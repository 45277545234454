/*
 * File: page-template-list.component.ts                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 1st June 2022 13:49:45                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

import { PageTemplate } from '../../interfaces/page-template.interface';
import { PageTemplateService } from '../../services/page-template.service';
import { UserService } from 'src/app/modules/user/services/user.service';
import { IUser } from 'src/app/modules/user/interfaces/user.interface';

import { MatSort, Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthModel } from 'src/app/modules/user/models/auth.model';

@Component({
  selector: 'app-page-template-list',
  templateUrl: './page-template-list.component.html',
  styleUrls: ['./page-template-list.component.scss'],
})
export class PageTemplateListComponent implements OnInit {
  dataTable: MatTableDataSource<PageTemplate>;
  loading: boolean = true;
  displayedColumns = ['name', 'columns', 'rows', 'actions'];

  checkModifiedTempaltes: Subscription;
  user: IUser;
  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private pageTemplateService: PageTemplateService,
    private userService: UserService
  ) {
    let auth = new AuthModel(api,cookie);
    this.user = auth.getCurrentUser();
    console.log(this.user);
  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
  
    this.subscriptions();
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.pageTemplateService.getTemplates().subscribe((data) => {
      this.dataTable = new MatTableDataSource<PageTemplate>(data);
      // this.dataTable.data = data;
      this.dataTable.sort = this.sort;

      this.loading = false;
    });
  }

  editTemplate(id: string) {
    this.pageTemplateService.editPageTemplateForm(this.user.id, id);
  }

  cloneTemplate(id:string)
  {
    this.pageTemplateService.cloneTemplate(this.user.id,id);
  }

  removeTemplate(id: string) {
    this.pageTemplateService.removeTemplate(id);
  }

  subscriptions() {
    this.checkModifiedTempaltes =
      this.pageTemplateService.templatesModified.subscribe((data) => {
        this.loadData();
      });
  }

  createTemplate(){
    console.log(this.user);
    this.pageTemplateService.newPageTemplateForm(this.user.id)
  }

}
