/*
 * File: api.module.ts                                                         *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 7th December 2022 12:22:26                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 7th December 2022 13:44:45                        *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { MenusModule } from '../menus/menus.module';
import { BackupsListComponent } from './components/backups-list/backups-list.component';
import { DropboxComponent } from './components/dropbox/dropbox.component';

@NgModule({
  imports: [BrowserModule, MatTableModule, MenusModule],
  declarations: [BackupsListComponent, DropboxComponent],
  exports: [BackupsListComponent, DropboxComponent],
  providers: [],
})
export class BackupModule {}
