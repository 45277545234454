import { NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { PopInfoService } from "./services/pop-info-service.service";

@NgModule({
    imports:[
        MatSnackBarModule
    ],
    providers: [],
})
export class PopInfoModule {}
