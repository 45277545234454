/*
 * File: product-toolbar-search-info.component.ts                              *
 * Project: catalog-cloud                                                      *
 * File Created: Sunday, 6th March 2022 23:00:15                               *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:57:34                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { ThemePalette } from '@angular/material/core';
import { Product } from '../../interfaces/product.interface';
import { ProductService } from '../../services/product.service';


@Component({
  selector: 'app-product-toolbar-search-info',
  templateUrl: './product-toolbar-search-info.component.html',
  styleUrls: ['./product-toolbar-search-info.component.scss'],
})
export class ProductToolbarSearchInfoComponent implements OnInit,AfterViewInit {
  @Input() allProducts: number;
  @Input() filteredProducts: number;
  @Input() baseProducts:number;
  @Input() showInsertedProducts: boolean;
  @Input() viewType: string;
  @Input() loading: boolean = true;
  @Input() productOrder:string;

  showProductDetails:boolean;

  @Output() viewTypeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() orderChange:EventEmitter<string> = new EventEmitter<string>();
  @Output() showInsertedProductsChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();


  @Output() forceReload: EventEmitter<boolean> = new EventEmitter<boolean>();

  toogleColor: ThemePalette = 'primary';

  @ViewChild('order') orderView: MatButtonToggleGroup;
  @ViewChild('productView') productView: MatButtonToggleGroup;


  constructor(private productService:ProductService) {}
  ngAfterViewInit(): void {
   this.loading = false
  }

  ngOnInit(): void {}

  toggleShowInsertedProducts() {
    this.showInsertedProducts = this.showInsertedProducts ? false : true;
    this.showInsertedProductsChange.emit(this.showInsertedProducts);
    this.forceReload.emit(true);
  }

  changeOrder(){
    if ((this.orderView) && (this.orderView.value)) this.orderChange.emit(this.orderView.value);
  }

  changeViewType() {
    if ((this.productView) && (this.productView.value)) this.viewTypeChange.emit(this.productView.value);
  }
}
