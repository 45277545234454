/*
 * File: catalog-list.component.ts                                             *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

import { CatalogService } from '../../services/catalog.service';
import { EsPaginatorIntl } from 'src/app/modules/tms-tools/helpers/es-paginator-intl';
import { Catalog } from '../../interfaces/catalog.interface';
import { FascicleService } from '../../services/fascicle.service';
import { Subscription } from 'rxjs';
import { FascicleSocketService } from '../../services/fascicle-socket.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ActionsMenuService } from 'src/app/modules/menus/services/actionsMenu.service';

    
@Component({
  selector: 'app-catalog-list',
  templateUrl: './catalog-list.component.html',
  styleUrls: ['./catalog-list.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: EsPaginatorIntl }],
})
export class CatalogListComponent implements OnInit, OnDestroy, AfterViewInit {
  
  private sortedData: Catalog[];

  totalChecks: number = 0;
  currentChecks: string[] = [];

  // icon variables
  icon_collapsed = 'expand_more';
  icon_expanded = 'expand_less';

  //if true expandable row shows
  isTableExpanded: Boolean = false;
  isActive: Boolean = false;

  catalog: Catalog;
  //All Columns
  allColumns: string[] = [
    'checkRow',
    'expandRow',
    'actions',
    'functions',
    'id',
    'name',
    'template',
    'createdAt',
    'updatedAt',
    'reference',
    'download',
  ];

  //Table columns displayed (and order)
  displayedColumns: string[] = [
    'checkRow',
    'name',
    'createdBy',
    'updatedAt',
    'pages',
    'actions',
    'expandRow',
  ];

  

  //Data for table
  dataCatalog: MatTableDataSource<Catalog> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  //if true table shows
  dataLoaded: boolean = false;
  disableSelect = new FormControl(false);

  //Subscriptions:
  fascicleSubscription: Subscription;
  dataLoadingSubscription: Subscription;
  dataSusbscription: Subscription;
  sidenavSubscription: Subscription;
  checks: any = [];
  selection = new SelectionModel<Catalog>(true, []);
  constructor(
    private actionMenuService: ActionsMenuService,
    private catalogService: CatalogService,
    private fascicleService: FascicleService,
    private fascicleSocketService: FascicleSocketService
  ) {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.fascicleSubscription.unsubscribe();
    this.dataLoadingSubscription.unsubscribe();
    this.dataSusbscription.unsubscribe();
    this.sidenavSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeToServices();
    this.listenForNewFasicle();
    this.catalogService.fetchCatalogList();
    this.collapseAll();
  }

  subscribeToServices() {
    this.sidenavSubscription = this.actionMenuService.activeItem.subscribe(
      (item) => {
        if (item.length == 0) {
          this.initializeBehaviour();
        }
      }
    );

    this.dataLoadingSubscription = this.catalogService.dataLoading.subscribe(
      (loading) => {
        this.dataLoaded = loading;
      }
    );

    this.dataSusbscription = this.catalogService.data$.subscribe((data) => {
      //this.paginator.page.pipe(tap(() => data));
      data.forEach((c) => {
        c['pages'] = 0;
        c.fascicles.forEach((f) => {
          c['pages'] += Number(f.totalPages);
        });
      });
      this.dataCatalog = new MatTableDataSource(data);
      //this.dataCatalog.paginator = this.paginator;

      this.dataCatalog.sort = this.sort;

      setTimeout(() => (this.dataCatalog.paginator = this.paginator));
      this.makeComputedSort();
      this.initializeTableExpansion();
    });
    this.listenForNewFasicle();
  }

  initializeBehaviour() {
    this.currentChecks = [];
    this.totalChecks = 0;
    this.actionMenuService.setCurrentCalaogSelected('');
  }

  //add property isExpanded to all datasource
  initializeTableExpansion() {
    this.isTableExpanded = false;
    this.isActive = false;
    this.dataCatalog.data.forEach((row: any) => {
      row.isExpanded = this.isTableExpanded;
      row.isActive = this.isActive;
    });
  }

  //Extend row with specific id
  extendRow(id: number) {
    this.dataCatalog.data.forEach((row: any) => {
      if (row.id == id) {
        if (!row.isExpanded) {
          //Expande
          document
            .getElementById('btn-expand-' + id)
            .classList.add('row-expanded');
          row.isExpanded = true;
          row.isActive = true;
        } else {
          //Colapsa
          document
            .getElementById('btn-expand-' + id)
            .classList.remove('row-expanded');

          row.isExpanded = false;
          row.isActive = false;
        }
      } else {
        if (row.isExpanded) {
          //Colapsa
          document
            .getElementById('btn-expand-' + id)
            .classList.remove('row-expanded');

          // document.getElementById('btn-expand-' + row.id).innerText =
          //   this.icon_collapsed;
          row.isExpanded = false;
          row.isActive = false;
        }
      }
    });
  }

  //Filter data
  applyFilter(event: KeyboardEvent) {
    var filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataCatalog.filter = filterValue;
  }

  checkAllToogle(event: MatCheckboxChange) {
    if (event.checked) {
      this.currentChecks = [];
      this.dataCatalog.data.forEach((row) => {
        this.selection.select(row);
        this.currentChecks.push(row.id);
      });
      this.actionMenuService.updateRowsChecked(this.dataCatalog.data.length);
      this.catalogService.selectedCatalogs = this.currentChecks;
    } else {
      this.selection.clear();
      this.currentChecks = [];
      this.actionMenuService.updateRowsChecked(0);
      this.catalogService.selectedCatalogs = this.currentChecks;
    }
  }

  /**
   * Checkar o no influye directamente en el comportamiento del menú lateral.
   *
   * @param event
   * @param id
   */
  rowChecked(event: MatCheckboxChange, id: string) {
    if (event.checked) {
      this.currentChecks.push(id);
      this.totalChecks++;
      if (this.currentChecks.length == 1)
        this.actionMenuService.setCurrentCalaogSelected(id);
      else this.actionMenuService.setCurrentCalaogSelected('');
    } else {
      let index = this.currentChecks.indexOf(id);
      this.currentChecks.splice(index, 1);
      if (this.currentChecks.length > 0) {
        this.actionMenuService.setCurrentCalaogSelected(
          this.currentChecks[this.currentChecks.length - 1]
        );
      } else this.actionMenuService.setCurrentCalaogSelected('');
    }
    this.actionMenuService.updateRowsChecked(this.currentChecks.length);
    this.catalogService.selectedCatalogs = this.currentChecks;
  }

  openCatalog(id: string) {
    this.catalogService.openForm(id);
  }

  calcTotalPages(catalog: Catalog) {
    let totalPages = 0;
    catalog.fascicles.forEach((f) => {
      totalPages = totalPages + f.totalPages;
    });
    return totalPages;
  }

  newFascicle(catalogId: string) {
    this.fascicleService.newFascicle(catalogId);
  }

  listenForNewFasicle() {
    this.fascicleSubscription = this.fascicleService.loadingFascicle.subscribe(
      (data) => {
        this.catalogService.fetchCatalogList();
      }
    );
  }

  collapseAll() {
    this.isTableExpanded = false;
    this.isActive = false;
    this.dataCatalog.data.forEach((row: any) => {
      let element = document.getElementById('btn-expand-' + row.id);
      if (element != null) {
        element.classList.remove('row-expanded');
        row.isExpanded = false;
        row.isActive = false;
      }
    });
  }

  sortCatalogs(e: any) {
    this.dataCatalog.sort.active = e.active;
    this.dataCatalog.sort.direction = e.direction;
  }

  /**
   * Se añade esta función para poder ordenadr el campo computado "CreatedBy"
   */
  makeComputedSort() {
    this.dataCatalog.paginator = this.paginator;
    this.dataCatalog.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'createdBy':
          return item.createdBy['name'] + ' ' + item.createdBy['surname'];
        default:
          return item[property];
      }
    };
    this.dataCatalog.sort = this.sort;
  }
}
