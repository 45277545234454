import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import jwt_decode from "jwt-decode";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "../../api/services/api.service";
import { PopInfoService } from "../../pop-info/services/pop-info-service.service";
import { ModalDialogComponent } from "../../tms-tools/components/modal-dialog/modal-dialog.component";
import { CreateComponent } from "../components/create/create.component";
import { EditComponent } from "../components/edit/edit.component";
import { UserModel } from "../models/user.model";
import { UserService } from "../services/user.service";

export class ModalUserForms {
    userModel: UserModel;
    constructor(
        private modalDialog: MatDialog,
        private translate: TranslateService,
        private popup: PopInfoService,
        api: ApiService,
        cookie: CookieService,
        private userService: UserService
    ) {
        this.userModel = new UserModel(api, cookie);
    }

    public registerForm() {
        this.modalDialog
            .open(ModalDialogComponent, {
                width: "954px",
                height: "auto",
                closeOnNavigation: false,
                disableClose: true,
                panelClass: "mat-elevation-z8",
                data: {
                    title: this.translate.instant("USER.ADD"),
                    component: CreateComponent,
                    type: "form",
                },
            })
            .afterClosed()
            .subscribe((createUserForm) => {
                let data = createUserForm["mainFields"];
                this.userModel.register(data).subscribe((result) => {
                    this.popup.addMessage(
                        "info",
                        this.translate.instant("USER.ADDED")
                    );
                    this.userService.userChanged.next(true);
                });
            });
    }

    public editForm(user: any) {
        this.modalDialog
            .open(ModalDialogComponent, {
                width: "954px",
                height: "auto",
                closeOnNavigation: false,
                disableClose: true,
                panelClass: "mat-elevation-z8",
                data: {
                    title: this.translate.instant("USER.EDIT"),
                    component: EditComponent,
                    type: "form",
                    user: user,
                },
            })
            .afterClosed()
            .subscribe((createUserForm) => {
                let data = createUserForm["mainFields"];
                this.userModel.update(user).subscribe((result) => {
                    this.popup.addMessage(
                        "info",
                        this.translate.instant("USER.MODIFIED")
                    );
                    this.userService.userChanged.next(true);
                    // this.userModel.setDataUser(user, true);
                    //this.userModel.user = data;
                    this.translate.setDefaultLang(user.lang);
                });
            });
    }
}
