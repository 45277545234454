import { NgModule } from "@angular/core";
import { BreadcrumbService } from "./services/breadcrumb.service";
import { TopBreadcrumbsComponent } from './components/top-breadcrumbs/top-breadcrumbs.component';
import { CommonModule } from "@angular/common";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports:[
      CommonModule,
      MatListModule,
      MatMenuModule,
      MatIconModule,
      RouterModule,
      FlexLayoutModule,
      TranslateModule
  ],
    providers: [BreadcrumbService],
    declarations: [
      TopBreadcrumbsComponent
    ],
    exports:[TopBreadcrumbsComponent]
})
export class BreadCrumbModule {}
