<div
  class="actions-opaco"
  fxLayout="row"
  fxFlex="100"
  fxLayoutAlign="space-around center"
>
  <button
    mat-flat-button
    matTooltip="{{ 'CATALOG.SYNC' | translate }}"
    (click)="syncCatalog()"
  >
    <mat-icon>sync</mat-icon>
  </button>

  <button
    mat-flat-button
    (click)="newFascicle()"
    matTooltip="{{ 'FASCICLE.CREATE' | translate }}"
    (click)="newFascicle()"
  >
    <mat-icon>auto_stories</mat-icon>
  </button>

  <button mat-flat-button [mat-menu-trigger-for]="actionsMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #actionsMenu="matMenu">
    <ng-container
      *ngFor="let button of actionsBunttos"
      [ngSwitch]="button.type"
    >
      <button
        *ngSwitchCase="'button'"
        mat-menu-item
        [ngClass]="{
          active: button.active
        }"
        [disabled]="button.disabled"
        (click)="button.clickButton()"
      >
        <mat-icon>{{ button.icon }}</mat-icon>
        <span>{{ button.tooltip | translate }}</span>
      </button>

      <mat-divider *ngSwitchCase="'separator'"></mat-divider>
    </ng-container>
  </mat-menu>
</div>
