<!--
File: side-menu.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:36:02
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->
<button
  *ngIf="isVisible"
  mat-flat-button
  color="primary"
  [matMenuTriggerFor]="actionMenu"
  aria-label="user-menu-button"
>
  <span> {{ "ACTIONS" | translate }} </span>
  <mat-icon matSuffix>expand_more</mat-icon>
</button>

<mat-menu #actionMenu="matMenu">
  <ng-container *ngFor="let button of actionsBunttos" [ngSwitch]="button.type">
    <button
      *ngSwitchCase="'button'"
      mat-menu-item
      [ngClass]="{
        active: button.active
      }"
      [disabled]="button.disabled"
      (click)="button.clickButton()"
    >
      <mat-icon>{{ button.icon }}</mat-icon>
      <span>{{ button.tooltip | translate }}</span>
    </button>

    <mat-divider *ngSwitchCase="'separator'"></mat-divider>
  </ng-container>
</mat-menu>

<!-- <mat-nav-list class="side-nav-cc">
  <div
    class="side-nav-cc-top mat-elevation-z2"
    fxLayout="row"
    fxLayoutAlign="space-around center"
    (click)="goToHome()"
  >
    <img
      class="menu-img"
      src="../../../../assets/img/nube_cc_200x140.png"

    />

  </div>





</mat-nav-list> -->
