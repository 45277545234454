/*
 * File: cc-grid.component.ts                                                  *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { CcGrid } from '../../models/cc-grid.model';
import { Page } from '../../interfaces/page.interface';
import { PageCell } from '../../interfaces/page-cell.interface';
import { PageTemplate } from '../../interfaces/page-template.interface';
import { PageService } from '../../services/page.service';
import { GridLocked } from 'src/app/modules/catalog-cloud/interfaces/grid-locked.interface';
import { UserService } from 'src/app/modules/user/services/user.service';
import { IUser } from 'src/app/modules/user/interfaces/user.interface';
import { ProductService } from '../../services/product.service';
import {  Subscription } from 'rxjs';
import { UserModel } from 'src/app/modules/user/models/user.model';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { AuthModel } from 'src/app/modules/user/models/auth.model';

@Component({
  selector: 'app-cc-grid',
  templateUrl: './cc-grid.component.html',
  styleUrls: ['./cc-grid.component.scss'],
})
export class CcGridComponent implements OnInit, OnDestroy {
  @Input() pageIndex: number;
  @Input() totalPages: number;
  @Input() baseGrids: PageTemplate[];
  @Input() idCatalog: string;
  @Input() idFascicle: string;
  @Input() idPage: string;
  @Input() isOdd: boolean;

  @Output() isLocked: EventEmitter<GridLocked> = new EventEmitter<GridLocked>();

  page: Page;
  currentPageTemplate: PageTemplate;

  grid: CcGrid;

  lockedButtons: boolean = true;
  initialLockButtos: boolean = true;

  loading: boolean = true;
  logging: boolean = false;

  cells: PageCell[];
  backupCells: PageCell[];
  backupPageTemplate: PageTemplate;

  //Cell sizes
  height: number = 0;
  width: number = 0;

  user: IUser;
  authModel:AuthModel;

  reloadSubs = new Subscription();
  changeSub = new Subscription();

  constructor(
    private pageService: PageService,
    private userService: UserService,
    private productService: ProductService,
    private cookieService: CookieService,
    private api: ApiService
  ) {
    
  }
  ngOnDestroy(): void {
    this.reloadSubs.unsubscribe();
    this.changeSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getCellSize();
    }, 1);
  }

  ngOnInit(): void {
    //TODO: DEBUG THIS ¿USer is correct?
    this.authModel = new AuthModel(this.api, this.cookieService);
    this.user = this.authModel.getCurrentUser();
    this.loadPageAndGrid();
    this.listenForChanges();
    this.loading = false;
  }

  getcurrentPageTemplate() {
    let baseIndex = 0;
    if (typeof this.page.pageTemplate === 'string') {
      baseIndex = this.baseGrids
        .map((b) => b.id)
        .indexOf(this.page.pageTemplate.toString());
      this.currentPageTemplate = this.baseGrids[baseIndex];
    } else {
      this.currentPageTemplate = this.page.pageTemplate as PageTemplate;
    }
  }

  loadPageAndGrid() {
    this.page = this.pageService.pages[this.pageIndex];
    if (this.page) {
      if (this.page.cells.length > 0) this.backupCells = this.page.cells;
      this.getcurrentPageTemplate();
      this.backupPageTemplate = this.currentPageTemplate;
      this.setGridFormat();
    }
  }

  listenForChanges() {
    this.reloadSubs = this.pageService.reloadGrid$.subscribe((data) => {
      this.loadPageAndGrid();
    });
    this.changeSub = this.pageService.pagesSubject.subscribe((data) => {
      this.loadPageAndGrid();
    });
  }

  changeFormat(format: PageTemplate) {
    console.log(this.user);
    this.pageService.setGrid(this.pageIndex, format, this.user.id);
    this.currentPageTemplate = format;
    // console.log('Indice de página:', this.pageIndex);
    this.page = this.pageService.pages[this.pageIndex];
    this.setGridFormat();
  }

  setGridFormat() {
    this.loading = true;
    this.grid = new CcGrid(
      this.currentPageTemplate.rows,
      this.currentPageTemplate.columns
    );
    this.grid.setGrid(this.page.cells);

    this.loading = false;
  }

  async saveGridFormat(saved: boolean) {
    if (!saved) {
      console.log("Grid not saved")
      this.pageService.rollBackPage(
        this.pageIndex,
        this.backupPageTemplate,
        this.backupCells
      );
      this.currentPageTemplate = this.backupPageTemplate;
      this.setGridFormat();
    } else {

      this.page.cells.forEach((cell) => {
        if (cell.product && !cell.visible)
          this.productService.removeProduct$.next(cell.product);
      });
      this.page = this.pageService.removeProductsOnHiddenCells(this.page);

      this.pageService.savePages().subscribe(async (data) => {
        // console.log('Save Grid Format', 'Saved Grid');
        console.log(data)
        this.page = data.pages[this.pageIndex];
        this.pageService.pages[this.pageIndex] = data.pages[this.pageIndex];

        this.setGridFormat();
      });
    }
  }

  lock(locked: boolean) {
    this.lockedButtons = locked;
    let emitLock: GridLocked = {
      index: this.pageIndex,
      locked: this.lockedButtons,
    };
    this.isLocked.emit(emitLock);
  }

  getCellSize() {
    this.height =
      document.getElementById('page-' + this.idPage).offsetHeight /
      this.grid.rows;

    this.width =
      document.getElementById('page-' + this.idPage).offsetWidth /
      this.grid.columns;
  }
}
