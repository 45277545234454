/*
 * File: catalog-wizard.component.ts                                           *
 * Project: catalog-cloud                                                      *
 * File Created: Monday, 24th January 2022 09:53:03                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */




import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CatalogService } from '../../services/catalog.service';

@Component({
  selector: 'app-catalog-wizard',
  templateUrl: './catalog-wizard.component.html',
  styleUrls: ['./catalog-wizard.component.scss'],
})
export class CatalogWizardComponent implements OnInit {
  @Input() id: string;

  @ViewChild('stepper') private stepWizzard: MatStepper;
  @Output() closeForm:EventEmitter<void>= new EventEmitter<void>();

  idCatalog: string;
  catalogInfoStep: FormGroup;
  catalogInfoStepLabel: string = 'Datos del catálogo';
  catalogChildForm: FormGroup;
  checkDifferencesStep: FormGroup;
  checkDifferencesStepLabel: string = 'Sincronización';
  syncStep: FormGroup;
  syncStepLabel: string = 'Sincronizar';
  catalogForm: FormGroup;



  catalogIsValid: boolean = false;
  preSyncIsValid:boolean = false;
  syncIsValid:boolean = false;

  constructor(
    private fb: FormBuilder,
    private catalogService: CatalogService
  ) {}

  changeMainForm(frmChild: FormGroup) {
    this.catalogInfoStep.controls['isValid'].setValue(frmChild.valid);
    this.catalogForm = frmChild;
  }

  ngOnInit(): void {
    this.catalogInfoStep = this.fb.group({
      isValid: ['', Validators.requiredTrue],
    });
    this.checkDifferencesStep = this.fb.group({
      isValid: ['', Validators.requiredTrue],
    });
    this.syncStep = this.fb.group({
      isValid: ['', Validators.requiredTrue],
    });
  }

  checkCatalogForm() {
    this.catalogService.save(this.catalogForm).subscribe((data) => {
      console.log(data);
      this.catalogForm.get('mainFields').get('id').setValue(data.id);
      this.idCatalog = data.id;

      this.stepWizzard.next();
    });
  }

  isPreSync(preSync:boolean)
  {
    this.preSyncIsValid = preSync;
    this.checkDifferencesStep.controls['isValid'].setValue(preSync);
  }

  goToSync(){
    this.stepWizzard.next();
  }

  setCloseForm(){
    this.closeForm.next();
  }

  setSyncFinish(){
    this.syncStep.controls['isValid'].setValue(true);
    this.syncIsValid = true;
  }
}
