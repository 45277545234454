/*
 * File: cc-grid-cell.model.ts                                                 *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:24:10                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { CcGrid } from './cc-grid.model';
import { PageCell } from '../interfaces/page-cell.interface';
export class CcGridCell {
  /**
   * Importante:
   * En la expansiones siempre se mantiene la celda de menor numero como celda maestra.
   */

  data: PageCell;

  parentCell: PageCell = null;

  /**
   * Celdas adjuntas a esta (Solo si esta expandida)
   */
  joinedCells: PageCell[] = [];

  constructor(private cell: PageCell, private grid: CcGrid) {
    this.data = cell;
  }

  getStyle() {
    this.data = this.cell;
    return {
      'grid-column-start': this.data.columnPosition,
      'grid-column-end': this.data.columnPosition + this.data.columnWith,
      'grid-row-start': this.data.rowPosition,
      'grid-row-end': this.data.rowPosition + this.data.rowWidth,
      width: `100%`,
    };
  }

  hide() {
    this.data.visible = false;
    this.data.columnWith = 0;
    this.data.rowWidth = 0;
    // this.data.value = '';
    this.joinedCells = [];
  }

  show() {
    this.data.visible = true;
  }

  reset() {
    this.data.columnWith = 1;
    this.data.rowWidth = 1;
    this.parentCell = null;
    this.joinedCells = [];
    this.data.visible = true;
  }

  goToCell(pos: number) {
    return this.grid.cells[pos];
  }

  cellLeft() {
    return this.grid.cells[this.data.cellNumber - 1];
  }

  cellRight() {
    return this.grid.cells[this.data.cellNumber + 1];
  }

  cellUp() {
    return this.grid.cells[this.data.cellNumber - this.grid.columns];
  }

  cellDown() {
    return this.grid.cells[this.data.cellNumber + this.grid.columns];
  }

  getJoinedCells() {
    return this.joinedCells;
  }

  getPreviousVisible(cellId: number): CcGridCell {
    if (cellId < 0) return null;
    let c = this.goToCell(cellId);
    if (c == null) return null;
    if (c.data.visible) return c;
    else {
      cellId--;
      return this.getPreviousVisible(cellId);
    }
  }

  getNextVisible(cellId: number): CcGridCell {
    if (cellId > this.grid.totalCells) {
      console.log('No existe visibles a la derecha');
      return null;
    }
    let c = this.goToCell(cellId);
    if (c == null) return null;

    if (c.data.visible) {
      return c;
    } else {
      cellId++;
      return this.getNextVisible(cellId);
    }
  }

  getTopVisible(cellId: number): CcGridCell {
    if (cellId < 0) return null;
    let c = this.goToCell(cellId);
    if (c == null) return null;
    if (c.data.visible == true) {
      return c;
    } else {
      cellId = cellId - this.grid.columns;
      return this.getTopVisible(cellId);
    }
  }

  getBottomVisible(cellId: number): CcGridCell {
    if (cellId > this.grid.totalCells) return null;
    let c = this.goToCell(cellId);
    if (c == null) return null;

    if (c.data.visible) {
      return c;
    } else {
      cellId = cellId + this.grid.columns;
      return this.getBottomVisible(cellId);
    }
  }

  /**********************EXPAND OPERATIONS **************************/

  /**
   * Expandir a la izquierda transfiere a una celda de numero menor todos las celdas heredadas en la acutal.
   * La actual pasa a ser una herdedada.
   */
  expandLeft() {
    let rows = this.data.rowWidth;
    let cLeft = this.getPreviousVisible(this.data.cellNumber - 1);
    let totalColumns = this.grid.columns;

    if (cLeft.isVerticalExpanded() == false && rows > 1) {
      for (let i = 1; i < rows; i++) {
        cLeft.combineRow(cLeft.data.cellNumber + totalColumns * i, true);
      }
    }

    cLeft.data.columnWith += this.data.columnWith;
    this.transferParentTo(cLeft.data.cellNumber, true);

    // this.reset();
    this.hide();
  }

  expandRight() {
    let cRight = this.getNextVisible(this.data.cellNumber + 1);
    console.log(cRight.isVerticalExpanded());
    let rows = this.data.rowWidth;
    let totalColumns = this.grid.columns;

    if (cRight.isVerticalExpanded() == false && rows > 1) {
      for (let i = 1; i < rows; i++) {
        this.combineRow(cRight.data.cellNumber + totalColumns * i, false);
      }
    }
    this.combineColumn(cRight.data.cellNumber);
  }

  expandTop() {
    let cTop = this.getTopVisible(this.data.cellNumber - this.grid.columns);
    let totalRows = this.data.rowWidth + cTop.data.rowWidth;

    let totalColums = this.data.columnWith;
    if (cTop.isHorizontalExpanded() == false && totalColums > 1) {
      for (let i = 1; i < totalColums; i++) {
        cTop.combineColumn(cTop.data.cellNumber + i);
      }
    }
    this.transferParentTo(cTop.data.cellNumber, true);

    cTop.data.rowWidth = totalRows;
  }

  expandBottom() {
    let cDown = this.getBottomVisible(this.data.cellNumber + this.grid.columns);
    let totalRows = this.data.rowWidth + cDown.data.rowWidth;
    let totalColumns = this.data.columnWith;

    if (cDown.isHorizontalExpanded() == false && totalColumns > 1) {
      for (let i = 1; i < totalColumns; i++) {
        cDown.combineColumn(cDown.data.cellNumber + i);
      }
    }

    cDown.transferParentTo(this.data.cellNumber, true);

    cDown.hide();
    this.data.rowWidth = totalRows;
  }

  /**********************SHRINK OPERATIONS **************************/

  shrinkLeft() {
    let cRight = this.cellRight();
    let rows = this.data.rowWidth;
    let totalColumns = this.grid.columns;

    cRight.data.columnWith = this.data.columnWith - 1;

    for (let i = 1; i < rows; i++) {
      let nextCell = this.grid.cells[this.data.cellNumber + totalColumns * i];
      this.splitCell(nextCell);
      nextCell.reset();
    }
    cRight.data.rowWidth = rows;

    this.transferParentTo(cRight.data.cellNumber, false);
    this.reset();
  }

  shrinkRight() {
    let cRight = this.goToCell(
      this.cell.cellNumber + (this.data.columnWith - 1)
    );

    let rows = this.data.rowWidth;
    let totalColumns = this.grid.columns;

    for (let i = 1; i < rows; i++) {
      let nextCell = this.grid.cells[cRight.data.cellNumber + totalColumns * i];
      this.splitCell(nextCell);
      nextCell.reset();
    }

    this.splitCell(cRight);

    cRight.reset();

    this.data.columnWith--;
  }

  shrinkTop() {
    let cDown = this.cellDown();
    let cols = this.data.columnWith;
    cDown.data.rowWidth = this.data.rowWidth - 1;

    for (let i = 1; i < cols; i++) {
      let nextCell = this.grid.cells[this.data.cellNumber + i];
      this.splitCell(nextCell);
      nextCell.reset();
    }

    cDown.data.columnWith = this.data.columnWith;

    this.transferParentTo(cDown.data.cellNumber, false);
    this.reset();
  }

  splitCell(cell: CcGridCell) {
    this.joinedCells.splice(this.joinedCells.indexOf(cell.data), 1);
  }

  shrinkBottom() {
    let cols = this.data.columnWith;
    let totalColumns = this.grid.columns;
    let cDown =
      this.grid.cells[
        this.data.cellNumber + totalColumns * (this.data.rowWidth - 1)
      ];
    for (let i = 1; i < cols; i++) {
      let nextCell = this.grid.cells[cDown.data.cellNumber + i];
      this.splitCell(nextCell);
      nextCell.reset();
    }
    this.splitCell(cDown);
    cDown.reset();

    this.data.rowWidth--;
  }

  isFirstOfRow() {
    if (this.isFirstOfGrid()) return true;
    return this.data.rowPosition != this.cellLeft().data.rowPosition;
  }

  isFirstOfGrid() {
    return typeof this.cellLeft() == 'undefined';
  }

  isLastOfRow() {
    if (this.isLastOfGrid()) return true;
    if (this.data.columnPosition + this.data.columnWith > this.grid.columns)
      return true;
    return this.data.rowPosition != this.cellRight().data.rowPosition;
  }

  isLastOfGrid() {
    return typeof this.cellRight() == 'undefined';
  }

  isOnTop() {
    return this.data.rowPosition == 1;
  }

  isOnBottom() {
    if (this.data.rowPosition + this.data.rowWidth > this.grid.rows)
      return true;
    return this.data.rowPosition == this.grid.rows;
  }

  isHorizontalExpanded() {
    if (this.parentCell != null) return this.parentCell.columnWith > 1;
    return this.data.columnWith > 1;
  }

  isVerticalExpanded() {
    if (this.parentCell != null) return this.parentCell.rowWidth > 1;
    return this.data.rowWidth > 1;
  }

  isExpanded() {
    return this.isHorizontalExpanded() || this.isVerticalExpanded();
  }

  isAlone() {
    return this.parentCell == null;
  }

  transferParentTo(cellId: number, addMe: boolean) {
    let newParentCell = this.grid.cells[cellId];
    if (!newParentCell.joinedCells) newParentCell.joinedCells = [];

    if (this.joinedCells != null)
      newParentCell.joinedCells = newParentCell.joinedCells.concat(
        this.joinedCells
      );

    if (addMe) {
      newParentCell.joinedCells.push(this.data);
    } else if (newParentCell.joinedCells.indexOf(newParentCell.data) != -1)
      newParentCell.joinedCells.splice(
        newParentCell.joinedCells.indexOf(newParentCell.data)
      );

    newParentCell.joinedCells.forEach((cell) => {
      this.grid.cells[cell.cellNumber].parentCell = newParentCell.data;
    });

    newParentCell.parentCell = null;
    newParentCell.show();

    if (addMe) this.parentCell = newParentCell.data;
    else this.parentCell = null;

    this.joinedCells = [];

    if (addMe) {
      this.hide();
    } else this.reset();
  }

  transferParentFrom(cellId: number) {
    this.joinedCells = this.grid.cells[cellId].joinedCells;
    this.grid.cells[cellId].joinedCells = [];
  }

  combineColumn(cellId: number) {
    let combinedCell = this.grid.cells[cellId];

    // if (!this.joinedCells) this.joinedCells = [];

    if (combinedCell.joinedCells != null)
      this.joinedCells = this.joinedCells.concat(combinedCell.joinedCells);

    this.joinedCells.push(combinedCell.data);

    this.joinedCells.forEach((cell) => {
      this.grid.cells[cell.cellNumber].parentCell = this.data;
      this.grid.cells[cell.cellNumber].data.visible = false;
    });

    this.data.columnWith = this.data.columnWith + combinedCell.data.columnWith;
    combinedCell.hide();
  }

  combineRow(cellId: number, calcRows: boolean) {
    let combinedCell = this.grid.cells[cellId];

    if (combinedCell.joinedCells != null)
      this.joinedCells = this.joinedCells.concat(combinedCell.joinedCells);

    this.joinedCells.push(combinedCell.data);

    this.joinedCells.forEach((cell) => {
      this.grid.cells[cell.cellNumber].parentCell = this.data;
      this.grid.cells[cell.cellNumber].data.visible = false;
    });

    if (calcRows)
      this.data.rowWidth = this.data.rowWidth + combinedCell.data.rowWidth;

    combinedCell.reset();
    combinedCell.hide();
    this.show();
  }
}
