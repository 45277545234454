/*
 * File: product-toolbar.component.ts                                          *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 12th January 2022 12:08:27                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:56:24                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Product } from '../../interfaces/product.interface';
import { FascicleService } from '../../services/fascicle.service';
import { ProductService } from '../../services/product.service';
import { keyValue } from 'src/app/modules/tms-tools/interfaces/key-value';
import { PopInfoService } from 'src/app/modules/pop-info/services/pop-info-service.service';

@Component({
  selector: 'app-product-toolbar',
  templateUrl: './product-toolbar.component.html',
  styleUrls: ['./product-toolbar.component.scss'],
})
export class ProductToolbarComponent implements OnInit, OnDestroy {
  @Input() idCatalog: string;
  @Input() idFascicle: string;
  @Input() filter: keyValue[];

  loading: boolean = true;
  viewType: string = 'list';

  products: Product[] = [];
  filteredProducts: Product[] = [];
  filteredProductsOriginal: Product[] = [];
  addedProducts: string[] = [];

  orderObserver: Subscription;

  showScrollToTop: boolean = false;
  productDataId = 'product-data';
  currentScroll = 0;

  constructor(private productService: ProductService) {}
  ngOnDestroy(): void {
    this.orderObserver.unsubscribe();
  }

  ngOnInit(): void {
    this.orderObserver = this.productService.orderHandler.subscribe((order) => {
      this.changeOrder(order);
    });
  }

  refreshList(productList: Product[]) {
    this.filteredProducts = productList;
    this.filteredProductsOriginal = JSON.parse(JSON.stringify(productList));
  }

  changeViewType(type: string) {
    this.viewType = type;
  }

  changeOrder(order: string) {
    switch (order) {
      case 'asc':
        this.filteredProducts = this.filteredProducts.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        break;
      case 'desc':
        this.filteredProducts = this.filteredProducts.sort((a, b) =>
          b.name.localeCompare(a.name)
        );
        break;
    }
  }

  scrollToTop() {
    document.getElementById(this.productDataId).scrollTop = 0;
  }

  scrolling(event: any) {
    let margin = 0;
    let offset = event.srcElement.offsetHeight;
    let totalWithscroll = event.srcElement.scrollHeight;
    let scrollTop = event.srcElement.scrollTop;
    let totalScroll = 0;

    if (totalWithscroll > offset) totalScroll = totalWithscroll - offset;
    let readyForSearch =  ((scrollTop + 50) >= totalScroll)
    if ((totalScroll >0) && ((scrollTop + 50) >= totalScroll)) this.productService.searchScrollHandler.next(true);

    // console.log("Scroll total:" + totalScroll + " -- ScrollTop:" + scrollTop + " -- Ready for search:" + readyForSearch);

  }
}
