import { EmailValidator } from "@angular/forms";
import { PasswordStrengthValidator } from "./password-strength.validator";
import { SameAsValidator } from "./same-as.validator";
import { UserRepeatedValidator } from "./user-repeated.validator";

export const FgValidators = {
    EmailValidator,
    PasswordStrengthValidator,
    SameAsValidator,
    UserRepeatedValidator,
};
