/*
 * File: user.service.ts                                                       *
 * Project: core                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 15th July 2022 13:30:13                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { IUser } from '../interfaces/user.interface';
import { ApiService } from '../../api/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userChanged: Subject<boolean> = new Subject<boolean>();
  logoOut: Subject<boolean> = new Subject<boolean>();
  user$: Subject<IUser | undefined> = new Subject<IUser | undefined>();
  user: IUser;

  constructor(private api: ApiService,
    private cookies:CookieService) {}

  public getUserById(id: string) {
    
  }

  public getRole() {
    // this.cookieService.set(
    //   environment.role_cookie_name,
    //   appRoleStr,
    //   0,
    //   "/",
    //   environment.base_domain,
    //   false
    // );
    const role = JSON.parse(this.cookies.get(environment.role_cookie_name));   
    return role['roleCode'];
  }
}
