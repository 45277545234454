/*
 * File: email.validator.ts                                                    *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 4th March 2022 10:13:12                               *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 4th March 2022 12:03:03                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
    AbstractControl,
    FormGroup,
    ValidationErrors,
    Validator,
    ValidatorFn,
} from "@angular/forms";

export function SameAsValidator(field1: string, field2: string): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
        let first = formGroup.get(field1);
        let second = formGroup.get(field2);
        if (first.value === second.value) second.setErrors(null);
        else second.setErrors({ message: "__fields_are_not_the_same" });
        return null;
    };
}
