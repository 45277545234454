/*
 * File: form-creator.ts                                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 28th February 2023 22:05:52                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

//Helper importante!!!

/**
 * Recivbe un objeto de tipo FormBuilder y otro de tipo
 * FormSchema (Marca de la casa) y convierte el objeto en un formulario.
 * Pendiente
 *  - Generación de validaciones
 */

import { Injectable } from '@angular/core';
import {
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FieldInterface } from '../interfaces/field.interface';
// import { FindValueOperator } from 'rxjs';

@Injectable()
export class FormCreator {
  private groups: string[] = [];
  private schema: Object;
  private fb: FormBuilder;
  private schemas: Object[] = [];
  public form: FormGroup = new FormGroup({});

  constructor(fb: FormBuilder) {
    this.fb = fb;
  }

  getControls(): FormGroup {
    try {
      let form: FormGroup = this.fb.group({});
      this.groups.forEach((group) => {
        if (group != 'default') {
          let formGroup: any = {};
          this.schemas[group].forEach((field: FieldInterface) => {
            formGroup[field.key] = this.setControl(field);
          });
          form.addControl(group, new FormGroup(formGroup));
        }
      });

      return form;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  getControlsByFormGroup(fgName: string): FormGroup {
    try {
      let fg = this.form.get(fgName) as FormGroup;
      return fg;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  setGroup(name: string) {
    this.groups.push(name);
    this.form.addControl(name, new FormGroup({}));
  }

  setGroups() {
    this.groups = Object.keys(this.schema);
  }

  schemaToForm(schema: Object) {
    let groupNames = Object.keys(schema);
    for (let g = 0; g < groupNames.length; g++)
    {
      let groupName = groupNames[g];
      this.schemas[groupName] = schema[groupName];
      this.setGroup(groupName);
      this.setControls(this.schemas[groupName],groupName);
    }

    // this.setGroup(schemaName);
    // let fb = this.setControls(this.schemas[schemaName], schemaName);
    // this.form.addControl(schemaName, fb);
    // this.setControls(this.schemas[schemaName], schemaName);
  }

  getFields(group: string): string[] {
    return Object.keys(this.schema[group]);
  }

  setControls(fields: FieldInterface[], group: string) {
    let fb: FormGroup = this.form.get(group) as FormGroup;
    fields.forEach((field) => {
      let fc = new FormControl(field.value ? field.value : '');
      if (field.required) fc.setValidators(Validators.required);
      if (field.validators)
        field.validators.forEach((val) => {
          fc.setValidators(val);
        });
      fb.addControl(field.key, fc);
    });
    return fb;
  }

  setControl(data: FieldInterface) {
    let fc = new FormControl(data.value ? data.value : '');
    if (data.required) fc.setValidators(Validators.required);

    if (data.validators)
      data.validators.forEach((val) => {
        fc.setValidators(val);
      });
    // if (data.val)
    return fc;
  }

  setFormArray(name: string) {
    this.form.addControl(name, new FormArray([]));
  }

  getFormArray(id: string): FormArray {
    return this.form.get(id) as FormArray;
  }

  setFormToArray(id: string, added: FormGroup) {
    let fa = this.form.controls[id] as FormArray;
    fa.push(added);
    return fa;
  }
}
