/*
 * File: cc-grid.model.ts                                                      *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:24:17                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { CcGridCell } from './cc-grid-cell.model';
import { PageCell } from '../interfaces/page-cell.interface';
export class CcGrid {
  rows: number = 0;
  columns: number = 0;
  totalCells: number = 0;
  cells: CcGridCell[] = [];

  constructor(rows: number, columns: number) {
    this.rows = rows;
    this.columns = columns;
    this.recalcCells();
  }

  setGrid(cells: PageCell[]) {
    let that = this;
    this.cells = [];
    cells.forEach((cell) => {
      this.cells.push(new CcGridCell(cell, that));
    });
  }

  setRows(rows: number) {
    this.rows = rows;
    this.recalcCells();
  }

  setColumns(columns: number) {
    this.columns = columns;
    this.recalcCells();
  }

  recalcCells() {
    this.totalCells = this.rows * this.columns;
  }

  getGridStyle() {
    return {
      'grid-template-columns': `repeat(${this.columns},minmax(0,1fr))`,
      'grid-auto-columns': `minmax(0,1fr)`,
      'grid-auto-flow': `column`,
      'grid-template-rows': `repeat(${this.rows},minmax(0,1fr))`,
    };
  }
}
