/*
 * File: catalog-delete.component.ts                                           *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:39:36                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */




import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CatalogService } from '../../services/catalog.service';
import { Catalog } from '../../interfaces/catalog.interface';
// import { ICatalog } from '../ICatalog.ts.bak';

@Component({
  selector: 'app-catalog-delete',
  templateUrl: './catalog-delete.component.html',
  styleUrls: ['./catalog-delete.component.scss']
})
export class CatalogDeleteComponent implements OnInit {

  @Input() idsCatalog:Array<string> = [];
  catalogsName:Array<string> = [];
  constructor(
    private catalogService: CatalogService,

  ) {

   }

  ngOnInit(): void {
    this.idsCatalog.forEach(id =>{
      this.catalogService.get(id).subscribe((catalog:Catalog) => {
        this.catalogsName.push(catalog.name);
      })
    })
  }



}
