<!--
 * File: backups-list.component.html                                           *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 16th June 2022 13:17:29                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 1st March 2023 14:52:33                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->

<cc-top-menu context="backups"></cc-top-menu>
<div style="width: 90%; margin: 2em auto">
  <table mat-table class="mat-elevation-z6" [dataSource]="data">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nombre</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef>Creador por</th>
      <td mat-cell *matCellDef="let element">{{ element.createdBy }}</td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Fecha creación</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt | date : "dd/MM/yyyy HH:mm" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef>Tamaño</th>
      <td mat-cell *matCellDef="let element">{{ element.size }} Mb</td>
    </ng-container>
    <ng-container matColumnDef="auto">
      <th mat-header-cell *matHeaderCellDef>Backup Automático</th>
      <td mat-cell *matCellDef="let element">{{ element.auto }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-stroked-button (click)="restore(element.id)">
          Restaurar
        </button>
        <button mat-stroked-button (click)="download(element.id)">
          Descargar
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <app-dropbox></app-dropbox> -->
</div>
