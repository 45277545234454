/*
 * File: catalog-form.schema.ts                                                *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:27:08                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { FormSchema } from '../../form-generator/interfaces/form-schema.interface';
export const catalogFormSchema: FormSchema = {
  mainFields: [
    {
      key: 'id',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 9,
    },
    {
      key: 'updateBy',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 8,
    },
    {
      key: 'updatedAt',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 7,
    },
    {
      key: 'createdBy',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 6,
    },
    {
      key: 'createdAt',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 5,
    },
    {
      type: 'text',
      value: '',
      label: 'CATALOG.INTERNAL_NAME',
      key: 'name',
      required: true,
      order: 1,
    },
    {
      key: 'title',
      type: 'text',
      required: true,
      value: '',
      label: 'CATALOG.TITLE',
      order: 2,
    },
    {
      key: 'connector',
      type: 'text',
      required: true,
      value: '',
      label: 'CATALOG.CONNECTOR',
      order: 3,
    },
    {
      key: 'privateKey',
      type: 'text',
      required: true,
      value: '',
      label: 'CATALOG.PRIVATE_KEY',
      order: 4,
    },
  ],
  extraFields: [],
};
