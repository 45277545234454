/*
 * File: user.module.ts                                                        *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 2nd December 2022 14:06:46                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 9th December 2022 10:36:32                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { OptionsUserMenuComponent } from "./components/options-user-menu/options-user-menu.component";
import { BrowserModule } from "@angular/platform-browser";
import { CreateComponent } from "./components/create/create.component";
import { FormGeneratorModule } from "../form-generator/form-generator.module";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { MatDividerModule } from "@angular/material/divider";
import { EditComponent } from "./components/edit/edit.component";
import { ListComponent } from "./components/list/list.component";
import { LoginComponent } from "./components/login/login.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { UpdateComponent } from "./components/update/update.component";
import {MatCardModule} from "@angular/material/card";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTableModule } from "@angular/material/table";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { AuthComponent } from "./components/auth/auth.component";


@NgModule({
    declarations: [
        OptionsUserMenuComponent,
        CreateComponent,
        EditComponent,
        ListComponent,
        LoginComponent,
        ResetPasswordComponent,
        UpdateComponent,
        AuthComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FormGeneratorModule,
        MatMenuModule,
        MatIconModule,
        RouterModule,
        MatDividerModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatCheckboxModule,
        MatTableModule,
        FlexLayoutModule,
        MatButtonModule,
        MatProgressSpinnerModule
    ],
    exports: [OptionsUserMenuComponent,LoginComponent,ResetPasswordComponent,CreateComponent,EditComponent,ListComponent],
})
export class UserModule {}
