/*
 * File: top-menu.component.ts                                                 *
 * Project: core                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 17th June 2022 11:06:19                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { IUser } from "src/app/modules/user/interfaces/user.interface";
import { SideMenuService } from "src/app/modules/menus/services/sideMenu.service";
import { UserService } from "src/app/modules/user/services/user.service";
import { UserModel } from "src/app/modules/user/models/user.model";
import { ApiService } from "src/app/modules/api/services/api.service";
import { CookieService } from "ngx-cookie-service";
import { AuthModel } from "src/app/modules/user/models/auth.model";

@Component({
    selector: "cc-top-menu",
    templateUrl: "./top-menu.component.html",
    styleUrls: ["./top-menu.component.scss"],
})
export class TopMenuComponent implements OnInit {
    user: IUser;
    inconMenu = "menu";
    sidenavIsExpanded: boolean = false;
    userModel:UserModel;
    auth:AuthModel;
    @Input() context: string;

    constructor(
        private sidenavService: SideMenuService,
        api:ApiService,
        cookie:CookieService
    ) {
        this.auth = new AuthModel(api,cookie);
        this.user = this.auth.getCurrentUser();
        this.userModel = new UserModel(api,cookie);
    }

    ngOnInit(): void {
    }

    toogleSideNav() {
        this.sidenavIsExpanded = !this.sidenavIsExpanded;
        this.sidenavService.toggle();
    }
}
