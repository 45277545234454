/*
 * File: user.model.ts                                                         *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Friday, 25th November 2022 13:19:33                           *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 24th February 2023 13:46:09                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */

import { CookieService } from "ngx-cookie-service";
import { ApiService } from "../../api/services/api.service";
import { IUser } from "../interfaces/user.interface";
import { UserRegister } from "../interfaces/user.register";
import { environment } from "src/environment";
import { firstValueFrom } from "rxjs";

export class UserModel {
  private userCookie = environment.app_cookie_name;
  private user: IUser;
  baseUrl = environment.auth_server;
  endpoints = {
    login: this.baseUrl + "users/login",
    register: this.baseUrl + "users/register",
    update: this.baseUrl + "users/",
    exists: this.baseUrl + "users/exists/",
    list: this.baseUrl + "users/list",
    get: this.baseUrl + "users/",
    generateToken: this.baseUrl + "users/generate-token/",
  };

  constructor(private api: ApiService, private cookie: CookieService) {
     }
  public register(user: UserRegister) {
    return this.api.post(this.endpoints.register, user);
  }

  public update(user: IUser) {

    return this.api.patch(this.endpoints.update + user.id, user);
  }

  public exists(username: string) {
    return this.api.get(this.endpoints.exists + username);
  }

  public getUsers() {
    return this.api.get(this.endpoints.list);
  }

  public async getUserById(userId: string): Promise<any> {
    return firstValueFrom(this.api.get(this.endpoints.get + userId));
  }
  public async generateToken(userId: string): Promise<any> {
    return firstValueFrom(
      this.api.post(this.endpoints.generateToken + userId, {})
    );
  }

  public reset() {
    this.user = undefined;
    this.cookie.set(
      this.userCookie,
      JSON.stringify(this.user),
      0,
      "/",
      environment.base_domain,
      true
    );
    //this.logoOut.next(true);
    localStorage.clear();
  }

  public logout() {
    this.cookie.delete(this.userCookie, "/", environment.base_domain);
    return Promise.resolve(undefined);
    //this.reset();
  }
}
