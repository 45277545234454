/*
 * File: product.service.ts                                                    *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 6th March 2023 10:04:14                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter } from 'smart-array-filter';
import { ModalDialogComponent } from '../../tms-tools/components/modal-dialog/modal-dialog.component';
import { keyValue } from '../../tms-tools/interfaces/key-value';
import { ProductFormComponent } from '../components/product-form/product-form.component';
import { Product } from '../interfaces/product.interface';
import { ApiParam, ApiParams } from '../../api/models/api-param.model';
import { ApiService } from '../../api/services/api.service';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private limitSearch: number = 20;
  offsetSearch: number = 0;
  private urlBase = environment.api_server;

  endPoints = {
    products: this.urlBase + '/products',
    search: this.urlBase + '/products/search/',
  };
  endPoint = this.urlBase + '/products';

  private addedProducts: string[] = [];

  addProduct$: Subject<string> = new Subject<string>();
  removeProduct$: Subject<string> = new Subject<string>();

  viewDetails: Subject<any> = new Subject<any>();
  showProductDetailsWindow: Subject<boolean> = new Subject<any>();
  searchScrollHandler: Subject<boolean> = new Subject<boolean>();

  orderHandler: Subject<string> = new Subject<string>();

  constructor(private api: ApiService, private modalDialog: MatDialog) {}

  countProducts(idCatalog: string, categories: string[], tags: string[]) {
    let url = this.endPoint + '/count/' + idCatalog;
    let paramCat = new ApiParam('categories', categories);
    let paramTag = new ApiParam('tags', tags);

    let params: ApiParams = new ApiParams();
    params.params.push(paramCat, paramTag);
    return this.api.get(url, params);
  }

  getProducts(idCatalog: string, filter: keyValue[]) {
    let url = this.endPoint + '/filter/' + idCatalog;
    let params: ApiParams = new ApiParams();
    for (let i = 0; i < filter.length; i++) {
      let param = new ApiParam(filter[i].id, filter[i].value);
      params.params.push(param);
    }
    return this.api.get(url, params);
  }

  getProduct(idCatalog: string, idProduct: string) {
    let url = this.endPoint + '/' + idProduct + '/catalog/' + idCatalog;

    return this.api.get(url);
  }

  getAddedProducts(idCatalog: string, idFascicle: string) {
    let url =
      this.endPoint +
      '/added-products/catalog/' +
      idCatalog +
      '/fascicle/' +
      idFascicle;
    return this.api.get(url);
  }

  postSearchProducts(
    idCatalog: string,
    filter: keyValue[],
    searchTerms: string[],
    order: string
  ) {
    let url = this.endPoints.search + idCatalog;
    let body = {};
    for (let i = 0; i < filter.length; i++) {
      if (filter[i].id == 'categories')
        Object.assign(body, { categories: filter[i].value });
      if (filter[i].id == 'tags')
        Object.assign(body, { tags: filter[i].value });
    }
    Object.assign(body, { order: order });
    Object.assign(body, { searchTerms: searchTerms });
    Object.assign(body, { limit: 0 });
    Object.assign(body, { skip: 0 });
    return this.api.post(url, body);
  }

  //Uso de la libreria smart-array-filter:
  andFilter(array: Product[], value, key) {
    const filterdata = filter(array, {
      keywords: value.join(','),
      caseSensitive: false,
    });
    return filterdata;
  }

  orFilter(array: Product[], value, key) {
    if (value.length > 0) {
      let allData: Product[] = [];
      value.forEach((val: string) => {
        let filterdata = filter(array, {
          keywords: val,
          caseSensitive: false,
        });
        allData.push(...filterdata);
      });

      return allData.filter((item, index) => allData.indexOf(item) === index);
    } else return this.andFilter(array, value, key);
  }

  offsetSearchIncrement() {
    this.offsetSearch = this.offsetSearch + this.limitSearch;
  }

  setProductForm(product: Product) {
    this.modalDialog
      .open(ModalDialogComponent, {
        width: '954px',
        height: 'auto',
        panelClass: 'mat-elevation-z8',
        data: {
          title: 'Sincronización del catálogo con Sales Layer',
          component: ProductFormComponent,
          entity: product,
          type: 'entity',
        },
      })
      .afterClosed()
      .subscribe((entity) => {
        console.log(entity);
        if (entity != false) {
          console.log('paso el filtro');
          this.addVersion(product.catalog.toString(), product).subscribe(
            (data) => {
              console.log('Nueva versión de producto');
              console.log(data);
            }
          );
        }
      });
  }

  addVersion(idCatalog: string, product: Product) {
    return this.api.post(this.endPoint + '/addVersion/' + idCatalog, product);
  }

  selectedProduct(item: any) {
    this.viewDetails.next(item);
  }

  getOffset() {
    return this.offsetSearch;
  }

  getAllProperties(products: Product[]) {
    let properties = [];
    products.forEach((product) => {
      let productKeys = Object.keys(product);
      productKeys.forEach((key) => {
        properties.indexOf(key) === -1 ? properties.push(key) : null;
      });
    });
    return properties;
  }

  addBaseToVariant(product: Product) {
    if (product.variants.length > 0) {
      console.log(product.variants);
    }
  }
}
