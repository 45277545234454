<!--
File: menu.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified:  Monday, 19th June 2023 1:02:47 pm
Modified By: Tomás (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<button
  mat-flat-button
  color="primary"
  [matMenuTriggerFor]="menu"
  aria-label="user-menu-button"
  class="button-option-user-menu"
>
  <mat-icon matPrefix>settings</mat-icon>
  <!-- <span>   {{ user.name }}</span>
  <mat-icon matSuffix>expand_more</mat-icon> -->
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item>
    <mat-icon matPrefix>person</mat-icon>
    <span>{{ user.name }} {{ role }}</span>
  </button>

  <button mat-menu-item (click)="editMe()">
    <mat-icon matPrefix>edit</mat-icon>
    <span>{{ "USER.EDIT_MY_DATA" | translate }} </span>
  </button>

  <button mat-menu-item *ngIf="role == 'SUPER_ADMIN'" [routerLink]="'/users/list/'">
    <mat-icon matPrefix>people_black</mat-icon>
    <span>{{ "USER.ADMIN_USERS" | translate }} </span>
  </button>

  <mat-divider *ngIf="role == 'SUPER_ADMIN'"> </mat-divider>
  <button
    mat-menu-item
    *ngIf="role == 'SUPER_ADMIN'"
    [routerLink]="'/page-templates/list/'"
  >
    <mat-icon matPrefix>web</mat-icon>
    <span>{{ "PAGE.TEMPLATE_LIST" | translate }} </span>
  </button>

  <button mat-menu-item *ngIf="role == 'SUPER_ADMIN'" [routerLink]="'/backups/list/'">
    <mat-icon matPrefix>settings_backup_restore</mat-icon>
    <span>{{ "BACKUPS" | translate }} </span>
  </button>

  <mat-divider *ngIf="role == 'SUPER_ADMIN'"></mat-divider>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>{{ "USER.CLOSE_SESSION" | translate }} </span>
  </button>
</mat-menu>
