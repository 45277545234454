/*
 * File: cc-cell-item-model-selector.component.ts                              *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 2nd March 2022 09:47:48                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 27th February 2023 15:59:20                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cc-cell-item-model-selector',
  templateUrl: './cc-cell-item-model-selector.component.html',
  styleUrls: ['./cc-cell-item-model-selector.component.scss']
})
export class CcCellItemModelSelectorComponent implements OnInit {

  @Input() models:string;
  @Input() modelSelected:string  = '';
  @Output() modelSelectedChange = new EventEmitter();

  formatedModels:string[] =[]
  constructor() { }

  ngOnInit(): void {
    this.formatedModels = this.models != undefined ? this.models.split(';'):[];

    if ((this.modelSelected.length == 0) && (this.formatedModels))
      this.modelSelected = this.formatedModels[0]

  }


  changeModel(model:string)
  {
    this.modelSelected = model;
    this.modelSelectedChange.emit(model);
  }

}
