/*
 * File: product-form.component.ts                                             *
 * Project: catalog-cloud                                                      *
 * File Created: Tuesday, 18th January 2022 17:58:14                           *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:55:06                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../interfaces/product.interface';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

  @Input() entity:Product;

  versions:Product[];
  currentVersion:Product;
  totalVersions:number;
  loading:boolean;

  constructor(private productService:ProductService) { }

  ngOnInit(): void {
    this.loading = true;
    this.versions = this.entity.versions.reverse();
    this.totalVersions = this.entity.versions.length + 1;
    if (this.totalVersions >1 ) this.currentVersion = this.entity.versions[this.totalVersions -2];
    else {this.currentVersion = this.entity}
    this.loading = false;
  }

}
