/*
 * File: reset-password.component.ts                                           *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  Router,
  Event as NavigationEvent,
  ActivatedRoute,
} from '@angular/router';
import {  TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { PopInfoService } from 'src/app/modules/pop-info/services/pop-info-service.service';
import { IUser } from '../../interfaces/user.interface';

/** Error when invalid control is dirty, touched, or submitted. */
export class ResetPassErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;

    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @Input() user: IUser | undefined;
  matcher = new ResetPassErrorMatcher();

  isSended: boolean = false;
  isLoading: boolean = false;
  sendedMessage: string = '';
  hasParameters: Promise<Boolean> = null;
  resetForm: Promise<Boolean> = null;
  goodToken: boolean = null;
  theToken: string = '';
  userId:string = '';

  frmSetPass: FormGroup;
  get fp() {
    return this.frmSetPass.controls;
  }

  newPassword: FormControl;
  confirmNewPassword: FormControl;

  endpoints ={
    checkMail: '/users/exists/',
    recover:'/users/recover/',
    checkToken:'/users/check-reset-token/',
    newPass:'/users/reset-password'
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(
    private api: ApiService,
    private activeRouter: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private cookie: CookieService,
    private popup: PopInfoService,
    private translate:TranslateService
  ) {

    activeRouter.queryParams.subscribe((param) => {
      if (param['token'] != null) {
        this.setNewPassForm();
        this.resetForm = Promise.resolve(false);
        this.hasParameters = Promise.resolve(true);

        this.checkToken(param['token']);
      } else {
        this.resetForm = Promise.resolve(true);
        this.hasParameters = Promise.resolve(false);
      }
    });
  }

  ngOnInit(): void {}

  tryReset() {
    if (this.emailFormControl.valid) {
      this.isLoading = true;
      var data = {
        email: this.emailFormControl.value,
      };
      this.api.get(this.endpoints.recover + data.email).subscribe(
        (response) => {
          this.isSended = true;
          this.isLoading = false;
        },
        (error) => {
          console.log('error:' + error);
          this.isSended = false;
          this.isLoading = false;
          this.popup.addMessage('warn', this.translate.instant('USER.NOT_EXIST'));
        }
      );
    }
  }

  checkToken(token: string) {
    this.api.get(this.endpoints.checkToken + token).subscribe((response) => {
      this.userId = response.id;
      this.goodToken = true
      this.theToken = token;
    });
  }

  setNewPassForm() {
    this.frmSetPass = this.fb.group(
      {
        newPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d]{8,30}$'
          ),
        ]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );
  }

  sendNewPass() {
    this.isLoading = true;
    var data = {
      userId: this.userId ,
      password: this.frmSetPass.controls['newPassword'].value,
      token: this.theToken,
    };

    this.api.post(this.endpoints.newPass, data).subscribe((response) => {
       this.router.navigate(['/']);
     });
  }
}
