/*
 * File: product-grid.component.ts                                             *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 12th January 2022 13:22:21                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 7th December 2022 10:16:06                        *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Product } from '../../interfaces/product.interface';

@Component({
  selector: 'app-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss'],
})
export class ProductGridComponent implements OnInit {
  @Input() items: Product[] = [];
  loading: boolean;

  scroll: any = '';

  constructor(
  ) {}

  ngOnInit(): void {}
}
