/*
 * File: catalog.service.ts                                                    *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 18:03:57                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { UserService } from '../../user/services/user.service';
import { ModalDialogComponent } from '../../tms-tools/components/modal-dialog/modal-dialog.component';
import { PopInfoService } from '../../pop-info/services/pop-info-service.service';
import { ActionsMenuService } from '../../menus/services/actionsMenu.service';
import { ApiService } from '../../api/services/api.service';
import { Catalog } from '../interfaces/catalog.interface';
import { CatalogForm } from '../interfaces/catalog-form.interface';
import { CatalogExtra } from '../interfaces/catalog-extra.interface';
import { CatalogDeleteComponent } from '../components/catalog-delete/catalog-delete.component';
import { CatalogWizardComponent } from '../components/catalog-wizard/catalog-wizard.component';
import { CatalogWizardSyncComponent } from '../components/catalog-wizard-sync/catalog-wizard-sync.component';
import { CatalogUploadComponent } from '../components/catalog-upload/catalog-upload.component';
import { CatalogSyncDifferencesComponent } from '../components/catalog-sync-differences/catalog-sync-differences.component';

import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environment';

@Injectable()
export class CatalogService {
  private hiddenFields = ['id', 'reference'];
  private mainFields = [
    'name',
    'template',
    'title',
    'prices',
    'variants',
    'backCoverText',
    'coverText',
  ];

  /**
   * Variables for datatables
   */
  private dataSubject = new ReplaySubject<Catalog[]>(1);
  data$: Observable<Catalog[]> = this.dataSubject.asObservable();

  dataLoading: Subject<boolean> = new Subject<boolean>();

  protected urlBase = environment.api_server;

  endPointCatalog = this.urlBase + '/catalogs';
  noPagination = '?pagination=false';

  selectedCatalogs: Array<string> = [];

  allDeleted: Subject<number> = new Subject<number>();

  //Duplicación

  private catalog: CatalogForm;

  constructor(
    private api: ApiService,
    private modalDialog: MatDialog,
    private popService: PopInfoService,
    private actionMenuService: ActionsMenuService,
    private trans: TranslateService
  ) {}

  /**
   * Se inicia un objeto del tipo Catalog-Form
   * @returns
   */
  intializeForm(): CatalogForm {
    return {
      mainFields: {
        id: '',
        createdAt: new Date(),
        createdBy: '',
        updateBy: '',
        updatedAt: new Date(),
        name: '',
        title: '',
        connector: '',
        privateKey: '',
      },
      extraFields: {
        extra: [],
      },
    };
  }

  initializeCatalog(): Catalog {
    return {
      id: '',
      createdAt: new Date(),
      createdBy: '',
      name: '',
      salesLayerCredentials: { key: '', pass: '' },
      title: '',
      backImage: '',
      categories: [],
      extra: [],
      fascicles: [],
      frontImage: '',
      products: [],
      tags: [],
      updateBy: '',
      updatetAt: new Date(),
    };
  }

  /**
   * Get catalog from server
   * @param id id Catalog
   * @returns
   */
  get(id: string) {
    console.log('Llego a la llamada de catalogos');
    if (id != '') {
      return this.api.get(this.endPointCatalog + '/' + id);
    } else {
      return new Observable((observer) => {
        //let data = this.intialize();
        // observer.next(data);
      });
    }
  }

  /**
   * Convert the data from the form to a compatible entity for backend
   * @param form data form from the catalog-form component
   * @returns
   */
  private mapFormToCatalogModel(form: FormGroup) {
    let catalog = this.initializeCatalog();
    let catalogKeys = Object.keys(catalog);
    let mainFields: FormGroup = form.get('mainFields') as FormGroup;

    let extraFields: FormGroup = form.get('extraFields') as FormGroup;
    // Se recogen datos principales
    catalogKeys.forEach((key) => {
      if (mainFields.controls[key]) {
        catalog[key] = mainFields.controls[key].value;
      }
    });
    catalog.salesLayerCredentials.key = mainFields.controls['connector'].value;
    catalog.salesLayerCredentials.pass =
      mainFields.controls['privateKey'].value;
    //Se recogen datos dinámicos insertados por el usuario
    for (var field in extraFields.controls) {
      var extraField: CatalogExtra = {
        name: field,
        value: extraFields.controls[field].value,
        label: field.replace(/_/g, ' '),
      };
      catalog.extra.push(extraField);
    }
    console.log('Catalogo para subir', catalog);
    return catalog;
  }

  /**
   * Comportamiento del formulario si se abre en modo de diálogo.
   * Ese formulario abre el wizard de edición de catálogo.
   * @param id
   */
  openForm(id: string) {
    this.modalDialog
      .open(ModalDialogComponent, {
        width: '954px',
        height: 'auto',
        closeOnNavigation: false,
        disableClose: true,
        panelClass: 'mat-elevation-z8',
        data: {
          title:
            id != ''
              ? this.trans.instant('CATALOG.EDIT')
              : this.trans.instant('CATALOG.CREATE'),
          component: CatalogWizardComponent,
          id: id,
          type: 'cancel',
        },
      })
      .afterClosed()
      .subscribe((wizardForm) => {
        this.fetchCatalogList();
        this.actionMenuService.reset();
      });
  }

  save(form: any) {
    let model: Catalog = this.mapFormToCatalogModel(form);
    if (model.id.length == 0) {
      return this.api.post(this.endPointCatalog, model);
    } else {
      return this.api.patch(this.endPointCatalog + '/' + model.id, model);
    }
  }

  /**
   * Save on old form, yty to remmove when wizard is ok
   * @param form
   */
  oldSave(form: any) {
    let model: Catalog = this.mapFormToCatalogModel(form);
    if (model.id.length == 0) {
      this.api.post(this.endPointCatalog, model).subscribe((data) => {
        this.fetchCatalogList();
        this.actionMenuService.reset();
        this.popService.addMessage('info', this.trans.instant('CATALOG.ADDED'));
      });
    } else {
      this.api
        .patch(this.endPointCatalog + '/' + model.id, model)
        .subscribe((data) => {
          this.fetchCatalogList();
          this.actionMenuService.reset();
          this.popService.addMessage(
            'info',
            this.trans.instant('CATALOG.ADDED')
          );
        });
    }
  }

  /**
   * Delete an array of catalogs
   * @param ids
   */

  delete(ids: Array<string>) {
    let i = 0;
    for (const id of ids) {
      this.api
        .delete(this.endPointCatalog + '/' + id)
        .subscribe((data: any) => {
          i++;
          this.allDeleted.next(i);
        });
    }
  }

  deleteCatalogs() {
    this.modalDialog
      .open(ModalDialogComponent, {
        width: 'auto',
        height: 'auto',
        panelClass: 'mat-elevation-z8',
        data: {
          title:
            this.selectedCatalogs.length > 1
              ? this.trans.instant('CATALOG.REMOVES')
              : this.trans.instant('CATALOG.REMOVE'),
          component: CatalogDeleteComponent,
          idsCatalog: this.selectedCatalogs,
          type: 'yesno',
        },
      })
      .afterClosed()
      .subscribe((remove) => {
        if (remove) this.delete(this.selectedCatalogs);
        this.allDeleted.subscribe((items) => {
          if (items == this.selectedCatalogs.length) {
            this.popService.addMessage(
              'info',
              items == 1
                ? this.trans.instant('CATALOG.REMOVE')
                : this.trans.instant('CATALOG.REMOVEDS')
            );
            this.fetchCatalogList();
            this.actionMenuService.reset();
          }
        });
      });
  }

  deleteCatalog(idCatalog:string) {
    this.modalDialog
      .open(ModalDialogComponent, {
        width: 'auto',
        height: 'auto',
        panelClass: 'mat-elevation-z8',
        data: {
          title:
            this.selectedCatalogs.length > 1
              ? this.trans.instant('CATALOG.REMOVES')
              : this.trans.instant('CATALOG.REMOVE'),
          component: CatalogDeleteComponent,
          idsCatalog: idCatalog,
          type: 'yesno',
        },
      })
      .afterClosed()
      .subscribe((remove) => {
        if (remove) this.delete([idCatalog]);
        this.allDeleted.subscribe((items) => {
          if (items == [idCatalog].length) {
            this.popService.addMessage(
              'info',
              items == 1
                ? this.trans.instant('CATALOG.REMOVE')
                : this.trans.instant('CATALOG.REMOVEDS')
            );
            this.fetchCatalogList();
            this.actionMenuService.reset();
          }
        });
      });
  }

  openSyncForm(id: string) {
    this.modalDialog.open(ModalDialogComponent, {
      width: '954px',
      height: 'auto',
      panelClass: 'mat-elevation-z8',
      data: {
        title: this.trans.instant('CATALOG.SYNC_TO_SL'),
        component: CatalogSyncDifferencesComponent,
        idCatalog: id,
        type: 'cancel',
      },
    });
  }

  openUploadForm(id: string) {
    this.modalDialog.open(ModalDialogComponent, {
      width: '450px',
      height: 'auto',
      panelClass: 'mat-elevation-z8',
      data: {
        title: this.trans.instant('CATALOG.EXPORT_TO_SL'),
        component: CatalogUploadComponent,
        idCatalog: id,
        type: 'cancel',
      },
    });
  }

  uploadToSs(id: string) {
    return this.api.get(this.endPointCatalog + '/' + id + '/export');
  }

  checkUploadToSs(id: string) {
    return this.api.get(this.endPointCatalog + '/' + id + '/check-export');
  }

  /***
   * Functions for catalg-list
   */

  getDataSource() {
    return this.api.get(this.endPointCatalog);
  }

  fetchCatalogList() {
    this.dataLoading.next(false);
    this.api.get(this.endPointCatalog).subscribe((res) => {
      console.log(res);
      this.dataSubject.next(res);
      this.dataLoading.next(true);
    });
  }

  getCatalog(id: string) {
    return this.api.get(this.endPointCatalog + '/' + id);
  }

  /**
   * Clone Functions
   */
  clone(id: string) {
    let cloneModel = {
      modelCatalogReference: id,
    };
    this.api
      .post(this.endPointCatalog + '/clone/' + id, cloneModel)
      .subscribe((data) => {
        this.popService.addMessage(
          'info',
          this.trans.instant('CATALOG.DUPLICATED')
        );
        this.fetchCatalogList();
        this.actionMenuService.reset();
      });
  }

  testSalesLayerConnection(key: string, pass: string) {
    let body = {
      key: key,
      pass: pass,
    };
    return this.api.post(this.endPointCatalog + '/testSync', body);
  }

  checkDifferencesSalesLayer(idCatalog: string) {
    return this.api.get(this.endPointCatalog + '/' + idCatalog + '/sync');
  }

  syncronizeSalesLayer(idCatalog: string) {
    return this.api.post(
      this.endPointCatalog + '/' + idCatalog + '/sync',
      null
    );
  }

  exportCsvHeaders(idCatalog: string) {
    return this.api.get(this.endPointCatalog + '/' + idCatalog + '/csv');
  }

  async getBreadCrumb(idCatalog: string) {
    const endpointCatalog: string = '/catalogs/';
    const c = await this.api.get(endpointCatalog + idCatalog).toPromise();
    let fascicles = c.fascicles.map((f) => {
      return { id: f.id, name: f.name };
    });
    return {
      breadcrumb: 'fascicle-list',
      catalogId: c.id,
      catalogName: c.name,
      fascicles: fascicles,
    };
  }
}
