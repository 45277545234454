/*
 * File: backup.service.ts                                                     *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 16th June 2022 13:26:16                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:20:26                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../../api/services/api.service';
import { PopInfoService } from '../../pop-info/services/pop-info-service.service';

@Injectable({
  providedIn: 'root',
})
export class BackupService {
  backuplist$: Subject<any> = new Subject<any>();
  constructor(private api: ApiService, private popup: PopInfoService) {}

  getList() {
    let endpoint = '/backups/list';
    this.api.get(endpoint).subscribe((data: any) => {
      this.backuplist$.next(data);
    });
  }

  createBackup(userId: string) {
    let endpoint = '/backups/ondemand';
    this.api.post(endpoint, { id: userId }).subscribe((data) => {
      this.getList();
      this.popup.addMessage('info', 'Backup guardado con éxito');
    });
  }

  restore(idBackup: string) {
    let endpoint = '/backups/restore';
    return this.api.post(endpoint, { id: idBackup });
  }

  download(idBackup: string) {
    let endpoint = '/backups/download/' + idBackup;
    return this.api.getFile(endpoint);
  }

  authDropbox() {
    let endpoint = '/dropbox';
    return this.api.get(endpoint);
  }
}
