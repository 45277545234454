/*
 * File: api.service.ts                                                        *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 4th July 2022 14:06:07                               *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment";
import { ApiParams } from "../models/api-param.model";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private cc_jwt: string = "";
  constructor(private http: HttpClient) {}
  private base_url = environment.api_server;

  setBaseUrl(url: string) {
    this.base_url = url;
  }

  getBaseUrl() {
    return this.base_url;
  }

  getFullUrl(endpoint: string) {
    var headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get<any>(endpoint, {
      headers: headers,
    });
  }

  get(endpoint: string, parameters?: ApiParams) {
    var headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params = params.set(param.key, param.value);
      });
      return this.http.get<any>( endpoint, {
        headers: headers,
        params: params,
      });
    } else {
      return this.http.get<any>( endpoint, {
        headers: headers,
      });
    }
  }

  getFile(endpoint: string, parameters?: ApiParams) {
    var headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/json');
    headers.append("Content-Type", "application/gzip");
    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params = params.set(param.key, param.value);
      });
      return this.http.get( endpoint, {
        responseType: "blob",
      });
    } else {
      return this.http.get( endpoint, {
        headers: headers,
        responseType: "blob",
      });
    }
  }

  getWithBody(endpoint: string, data: any, parameters?: ApiParams) {
    var headers = new HttpHeaders();
    var body = data;
    headers.append("Content-Type", "application/json");

    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params.append(param.key, param.value);
      });
      return this.http.get<any>( endpoint, {
        headers: headers,
        params: params,
        observe: body,
      });
    } else {
      return this.http.get<any>( endpoint, {
        headers: headers,
        observe: body,
      });
    }
  }

  post(endpoint: string, data: any, parameters?: ApiParams) {
    var body = data;
    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params.append(param.key, param.value);
      });
      
      return this.http.post<any>( endpoint, body, {
        params: params,
      });
    } else {
      return this.http.post<any>( endpoint, body);
    }
  }

  postFile(endpoint: string, data: any, parameters?: ApiParams) {
    var body = data;
    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params.append(param.key, param.value);
      });

      return this.http.post<any>( endpoint, body, {
        params: params,
      });
    } else {
      return this.http.post<any>( endpoint, body);
    }
  }

  put(endpoint: string, data: any, parameters?: ApiParams) {
    var body = data;
    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params.append(param.key, param.value);
      });
      return this.http.put<any>( endpoint, body, {
        params: params,
      });
    } else {
      return this.http.put<any>( endpoint, body);
    }
  }

  patch(endpoint: string, data: any, parameters?: ApiParams) {
    var body = data;
    if (parameters != null && parameters.params.length > 0) {
      let params = new HttpParams();
      parameters.params.forEach((param) => {
        params.append(param.key, param.value);
      });
      return this.http.patch<any>( endpoint, body, {
        params: params,
      });
    } else {
      return this.http.patch<any>( endpoint, body);
    }
  }

  delete(endpoint: string) {
    return this.http.delete<any>( endpoint);
  }
}
