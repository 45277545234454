/*
 * File: cc-cell-item.component.ts                                             *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 6th March 2023 10:02:21                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CellService } from '../../services/cell.service';
import { Product } from '../../interfaces/product.interface';
import { ProductExtra } from '../../interfaces/product-extra';
import { ProductVariant } from '../../interfaces/product-variant';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-cc-cell-item',
  templateUrl: './cc-cell-item.component.html',
  styleUrls: ['./cc-cell-item.component.scss'],
})
export class CcCellItemComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() height: any;
  @Input() width: any;
  @Input() idPage:string;
  @Output() remove = new EventEmitter<boolean>();
  @Output() productModelChange = new EventEmitter<string>();
  @Output() productVariantChange = new EventEmitter<ProductVariant>();
  @Output() productExtraChange = new EventEmitter<ProductExtra[]>();

  @Input() currentModel: string;
  @Input() currentVariant: ProductVariant;
  @Input() currentExtra: ProductExtra[];

  selected: boolean = false;
  imSelected: Subscription = new Subscription();

  constructor(
    private productService: ProductService,
    private cellService: CellService
  ) {}

  ngOnDestroy(): void {
    this.imSelected.unsubscribe();
  }

  ngOnInit(): void {
    this.imSelected = this.productService.viewDetails.subscribe(
      (data: Product) => {
        if (data.id != this.item.id) this.selected = false;
        else this.selected = true;
      }
    );
  }

  removeIt() {
    this.remove.emit(true);
  }

  // openProductForm(e:any)
  // {
  //   // console.log(e);
  //   this.productService.setProductForm(e);
  // }

  updateModel(e: any) {
    this.currentModel = e;
    this.productModelChange.emit(e);
  }

  updateVariant(e: ProductVariant) {
    this.currentVariant = e;
    this.productVariantChange.emit(e);
  }

  select(item) {
    this.productService.selectedProduct(item);
  }

  openExtraForm() {
    this.cellService.openExtraFieldsForm(
      this.currentExtra.length > 0 ? this.currentExtra : this.item.extra
    ).subscribe((extra)=>{
     if(extra) this.productExtraChange.emit(extra);
    });
  }
}
