<!--
File: cc-cell-item.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified:  Tuesday, 16th January 2024 1:43:50 pm
Modified By: Tomás (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<div
  class="cell-card"
  cdkDrag
  [cdkDragData]="[item, currentModel, currentVariant,currentExtra,idPage]"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  (click)="select(item)"
  [class.selected]="selected"
  (dblclick)="openExtraForm()"
>
  <div
    class="cell-toolbar"
    fxLayout="row"
    fxFill
    fxLayoutAlign="space-between start"
  >
    <button mat-icon-button (click)="removeIt()">
      <mat-icon>delete_forever</mat-icon>
    </button>

    <app-cc-cell-item-variant-selector
      [variants]="item.variants"
      (variantSelectedChange)="updateVariant($event)"
      style="z-index: 1"
    ></app-cc-cell-item-variant-selector>

    <app-cc-cell-item-model-selector
      [models]="item.modelos"
      [modelSelected]="currentModel"
      (modelSelectedChange)="updateModel($event)"
      style="z-index: 1"
    ></app-cc-cell-item-model-selector>
  </div>

  <div *cdkDragPreview class="item-drag-product">
    <img
      [src]="item.img == null ? './assets/img/nube_cc_200x140.png' : item.img"
    />
  </div>

  <div
    class="cell-card-header"
    fxLayout="row"
    fxFill
    fxLayoutAlign="center start"
    [id]="item.id"
  >
    <img
      class="img-product"
      [src]="currentVariant ? currentVariant.image:item.img"
    />
  </div>

  <div class="cell-card-content">
    <div
      class="cell-current-model"
      *ngIf="currentModel && currentModel != 'Base'"
    >
      <div class="cell-current-model-data">
        <mat-icon>star</mat-icon> <span>{{ currentModel }}</span>
      </div>
    </div>
    <span class="product-title">{{ currentVariant ? currentVariant.name:item.name }}</span>
    <span class="product-sku">Sku: {{ currentVariant ? currentVariant.sku:item.sku }}</span>
  </div>
</div>
<app-product-details [item]="item"></app-product-details>
