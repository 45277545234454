/*
 * File: catalog-upload.component.ts                                           *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 16th February 2022 13:06:31                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:43:04                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, Input, OnInit } from '@angular/core';
import { CatalogService } from '../../services/catalog.service';

@Component({
  selector: 'app-catalog-upload',
  templateUrl: './catalog-upload.component.html',
  styleUrls: ['./catalog-upload.component.scss']
})
export class CatalogUploadComponent implements OnInit {
  loading:boolean = false;
  uploaded:boolean = false;
  @Input() idCatalog:string;
  checkData:any  = undefined;
  constructor(private catalogService:CatalogService) { }

  ngOnInit(): void {
    this.checkUpload();
  }

  checkUpload(){
    this.loading = true;
    this.catalogService.checkUploadToSs(this.idCatalog).subscribe(data => {
      console.log(data);
      this.checkData = data;
      this.loading = false

    });
  }
  upload(){

    this.checkData = undefined;
    this.loading = true;
    this.catalogService.uploadToSs(this.idCatalog).subscribe(data => {
      this.uploaded = true;
      this.loading = false
      console.log(data);
    });;
  }
}
