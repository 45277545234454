/*
 * File: fascicle.resolver.ts                                                  *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 9th March 2022 14:41:17                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:24:49                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';

import { FascicleService } from '../services/fascicle.service';

@Injectable({
  providedIn: 'root',
})
export class FascicleResolver implements Resolve<any> {
  constructor(private fascicleService: FascicleService) {}
  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    var idCat = route.paramMap.get('idCatalog');
    var idFas = route.paramMap.get('id');
    let fasicleResolved = await this.fascicleService.getBreadCrumb(
      idCat,
      idFas
    );
    return fasicleResolved;
  }
}
