/*
 * File: cc-grid-selector.component.ts                                         *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageTemplate } from '../../interfaces/page-template.interface';
import { PageService } from '../../services/page.service';

@Component({
  selector: 'app-cc-grid-selector',
  templateUrl: './cc-grid-selector.component.html',
  styleUrls: ['./cc-grid-selector.component.scss']
})
export class CcGridSelectorComponent implements OnInit {


  @Input() grids:PageTemplate[];
  @Input() currentGrid:PageTemplate;
  @Input() lock:boolean = true;
  @Input() pageId:string;
  @Output() locked:EventEmitter<boolean>= new EventEmitter<boolean>();
  @Output() newGridFormat:EventEmitter<any>= new EventEmitter<any>();
  @Output() saveFormat:EventEmitter<boolean> = new EventEmitter<boolean>();

  lockedIcon:string = 'lock_open';
  cancelIcon:string = 'cancel';
  selectedGrid:PageTemplate;
  backupCurrentGrid:PageTemplate;

  constructor(private pageService:PageService) { }

  ngOnInit(): void {
    this.orderGridList();
    this.lockedIcon = this.lock ? 'lock':'lock_open';
    this.selectedGrid = this.currentGrid;
    this.backupCurrentGrid = JSON.parse(JSON.stringify(this.currentGrid));

  }

  orderGridList(){
    this.grids = this.grids.sort(function (a,b) {
      let nameA = a.name;
      let nameB = b.name;
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }
  toggle() {
    if (this.lock) this.lock = false;
    else this.lock =true;

    this.lockedIcon = this.lock ? 'lock':'lock_open';
    this.locked.emit(this.lock);
    if (this.lock)
    {
      this.save();
    }
  }

  save(){
    this.saveFormat.emit(true);
  }

  cancel(){
    this.selectedGrid = this.backupCurrentGrid;
    this.changeFormat(this.backupCurrentGrid.id);
    this.toggle();
    this.saveFormat.emit(false);
  }

  changeFormat(evento:any){
    let grid = this.grids.filter(f => {return f['id'] === evento.value})[0];
    this.newGridFormat.emit(grid);
  }

  removePage(id: any) {
    this.pageService.deleteModal(id).subscribe((remove)=>{
      if (remove) this.pageService.removePage(id);
    })

  }

}
