/*
 * File: cc-cell-item-extra-fields-form.component.ts                           *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 27th May 2022 09:15:01                                *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 7th December 2022 10:06:28                        *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, Input, OnInit } from '@angular/core';
import { ProductExtra } from '../../interfaces/product-extra';

@Component({
  selector: 'app-cc-cell-item-extra-fields-form',
  templateUrl: './cc-cell-item-extra-fields-form.component.html',
  styleUrls: ['./cc-cell-item-extra-fields-form.component.scss']
})
export class CcCellItemExtraFieldsFormComponent implements OnInit {
  @Input() entity:ProductExtra[];

  constructor() { }

  ngOnInit(): void {
  }

}
