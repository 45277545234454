/*
 * File: side-menu.component.ts                                                *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from 'src/app/modules/user/interfaces/user.interface';
import { ActionsMenuService } from '../../services/actionsMenu.service';
import { CatalogService } from 'src/app/modules/catalog-cloud/services/catalog.service';
import { FascicleService } from 'src/app/modules/catalog-cloud/services/fascicle.service';
import { PageService } from 'src/app/modules/catalog-cloud/services/page.service';
import { PageTemplateService } from 'src/app/modules/catalog-cloud/services/page-template.service';
import { UserService } from 'src/app/modules/user/services/user.service';
import { BackupService } from 'src/app/modules/backups/services/backup.service';
import { ModalUserForms } from 'src/app/modules/user/helpers/modal-user-forms.helper';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PopInfoService } from 'src/app/modules/pop-info/services/pop-info-service.service';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/modules/api/services/api.service';
import * as JSZip from 'jszip';


export enum checkStatus {
  Zero = 0,
  One = 1,
  moreThanOne = 2,
}

@Component({
  selector: 'cc-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss'],
})
export class ActionsMenuComponent implements OnInit {
  @Input() clickedItem: string = '';
  @Input() context: string = '';

  events: string[] = [];
  isExpanded: boolean = false;
  toogleIcon = 'arrow_right';
  isVisible: boolean = false;

  downloadJsonHref;

  user: IUser;
  inconMenu = 'menu';
  sidenavIsExpanded: boolean = false;

  actionsBunttos: any[];
  fascicleButtons: any[];

  /**
   * Checks control
   */
  checkControl: checkStatus = checkStatus.Zero;

  userModalForms: ModalUserForms;
  constructor(
    private actionsService: ActionsMenuService,
    private catalogService: CatalogService,
    private fascicleService: FascicleService,
    private pageService: PageService,
    private pageTemplateService: PageTemplateService,
    private userService: UserService,
    private backupService: BackupService,
    private router: Router,
    api:ApiService,
    dialog: MatDialog,
    translate: TranslateService,
    popup: PopInfoService,
    cookie: CookieService
  ) {
    this.userModalForms = new ModalUserForms(
      dialog,
      translate,
      popup,
      api,
      cookie,
      userService
    );
  }

  ngOnInit(): void {
    this.user = this.userService.user;
    this.setButtons();
    this.expandSideNav();
    this.checkRows();
    this.listenForCheckItem();
  }

  checkRows() {
    this.actionsService.totalRowsChecked.subscribe((totalRows) => {
      this.setListBehaviour(totalRows);
      this.setButtons();
    });
  }

  listenForCheckItem() {
    this.actionsService.activeItem.subscribe((item: string) => {
      this.clickedItem = item;
    });
  }

  expandSideNav() {
    this.actionsService.change.subscribe((isOpen) => {
      this.isExpanded = isOpen;
      if (isOpen) this.toogleIcon = 'arrow_left';
      else this.toogleIcon = 'arrow_right';
    });
  }

  setListBehaviour(totalRows: number) {
    if (totalRows == 0) {
      this.checkControl = checkStatus.Zero;
    } else if (totalRows == 1) {
      this.checkControl = checkStatus.One;
    } else {
      this.checkControl = checkStatus.moreThanOne;
    }
  }

  newCatalog() {
    this.actionsService.setMenu('newCatalog');
    if (this.checkControl == 0) this.catalogService.openForm('');
  }

  editCatalog() {
    this.actionsService.setMenu('newCatalog');
    if (this.checkControl == 1)
      this.catalogService.openForm(this.actionsService.currentCatalogId);
  }

  newFascicle() {
    if (this.checkControl == 1)
      this.fascicleService.newFascicle(this.actionsService.currentCatalogId);
  }

  removeCatalogs() {
    if (this.checkControl != 0) {
      this.actionsService.setMenu('delCatalog');
      this.catalogService.deleteCatalogs();
    }
  }

  cloneCatalog() {
    if (this.checkControl == 1) {
      this.catalogService.clone(this.catalogService.selectedCatalogs[0]);
    }
  }

  syncCatalog() {
    this.actionsService.setMenu('syncCatalog');
    if (this.checkControl == 1)
      this.catalogService.openSyncForm(this.actionsService.currentCatalogId);
  }

  exportCsv() {
    var zip:JSZip = typeof(<any>JSZip).default === "function" ? new (<any>JSZip).default() : undefined;
    this.catalogService
      .exportCsvHeaders(this.actionsService.currentCatalogId)
      .subscribe(
        async (data) => {
          let head = data.head;
          let lines = data.lines;
          zip.file(
            'head_' + this.actionsService.currentCatalogId + '.csv',
            head
          );
          zip.file(
            'lines_' + this.actionsService.currentCatalogId + '.csv',
            lines
          );
          zip.generateAsync({ type: 'base64' }).then((zipped) => {
            var downloader = document.createElement('a');
            downloader.setAttribute(
              'href',
              'data:text/plain;base64;charset=utf-8,' + zipped
            );
            downloader.setAttribute(
              'download',
              'csv_schema_catalog_' +
                this.actionsService.currentCatalogId +
                '.zip'
            );
            downloader.click();
          });

          // this.actionsService.reset();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  exportCatalog() {
    this.catalogService.openUploadForm(this.actionsService.currentCatalogId);
  }

  toogleSideNav() {
    this.sidenavIsExpanded = this.sidenavIsExpanded ? false : true;
    if (this.inconMenu == 'menu') this.inconMenu = 'close';
    else this.inconMenu = 'menu';
    this.actionsService.toggle();
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  newPageTempalte() {
    this.router.navigate(['/page-templates/new']);
  }

  setButtons() {
    const catalogButtons = [
      {
        name: 'newCatalog',
        disabled: this.checkControl > 0,
        active: this.clickedItem === 'newCatalog',
        clickButton: () => this.newCatalog(),
        tooltip: 'MENU.NEW_CATALOG',
        tooltipPostion: 'right',
        icon: 'note_add',
        type: 'button',
      },
      {
        name: 'cloneFascicle',
        disabled: this.checkControl == 0 || this.checkControl > 1,
        active: this.clickedItem === 'clonCatalog',
        clickButton: () => this.cloneCatalog(),
        tooltip: 'MENU.CLONE_CATALOG',
        tooltipPostion: 'right',
        icon: 'content_copy',
        type: 'button',
      },
      {
        name: 'editCatalog',
        disabled: this.checkControl == 0 || this.checkControl > 1,
        active: this.clickedItem === 'editCatalog',
        clickButton: () => this.editCatalog(),
        tooltip: 'MENU.EDIT_CATALOG',
        tooltipPostion: 'right',
        icon: 'settings',
        type: 'button',
      },
      {
        type: 'separator',
        info: 'End of Catalog Menu',
      },
      {
        name: 'newFascicle',
        disabled: this.checkControl == 0 || this.checkControl > 1,
        active: this.clickedItem === 'newFascicle',
        clickButton: () => this.newFascicle(),
        tooltip: 'MENU.NEW_FASCICLE',
        tooltipPostion: 'right',
        icon: 'auto_stories',
        type: 'button',
      },
      {
        type: 'separator',
        info: 'End of Fascicle Menu',
      },
      {
        name: 'syncCatalog',
        disabled: this.checkControl == 0 || this.checkControl > 1,
        active: this.clickedItem === 'syncCatalog',
        clickButton: () => this.syncCatalog(),
        tooltip: 'MENU.SYNC_CATALOG',
        tooltipPostion: 'right',
        icon: 'sync',
        type: 'button',
      },
      {
        name: 'exportCatalog',
        disabled: this.checkControl == 0 || this.checkControl > 1,
        active: this.clickedItem === 'exportCatalog',
        clickButton: () => this.exportCatalog(),
        tooltip: 'MENU.EXPORT_CATALOG',
        tooltipPostion: 'right',
        icon: 'ios_share',
        type: 'button',
      },
      {
        name: 'exportCsv',
        disabled: this.checkControl == 0 || this.checkControl > 1,
        active: this.clickedItem === 'exportCsv',
        clickButton: () => this.exportCsv(),
        tooltip: 'MENU.EXPORT_CSV',
        tooltipPostion: 'right',
        icon: 'subject',
        type: 'button',
      },
      {
        type: 'separator',
        info: 'End of Catalog Export Menu',
      },

      {
        name: 'delCatalog',
        disabled: this.checkControl == 0,
        active: this.clickedItem === 'delCatalog',
        clickButton: () => this.removeCatalogs(),
        tooltip: 'MENU.DEL_CATALOG',
        tooltipPostion: 'right',
        icon: 'delete_outline',
        type: 'button',
      },
    ];

    const fascicleButtons = [
      {
        name: 'addPage',
        disabled: false,
        active: true,
        clickButton: () => this.pageService.addPage(this.user.id),
        tooltip: 'MENU.ADD_PAGE',
        tooltipPostion: 'right',
        icon: 'post_add',
        type: 'button',
      },
    ];

    const userButtons = [
      {
        name: 'addUser',
        disabled: false,
        active: true,
        clickButton: () => this.userModalForms.registerForm(),
        tooltip: 'MENU.ADD_USER',
        tooltipPostion: 'right',
        icon: 'person_add',
        type: 'button',
      },
    ];

    const pageTemplateButtons = [
      {
        name: 'addPageTemplate',
        disabled: false,
        active: true,
        clickButton: () =>
          this.pageTemplateService.newPageTemplateForm(this.user.id),
        tooltip: 'PAGE.ADD',
        tooltipPostion: 'right',
        icon: 'add',
        type: 'button',
      },
    ];

    const backupButtons = [
      {
        name: 'addBackup',
        disabled: false,
        active: true,
        clickButton: () => this.backupService.createBackup(this.user.id),
        tooltip: 'BACKUPS_ADD',
        tooltipPostion: 'right',
        icon: 'cloud_upload',
        type: 'button',
      },
    ];

    switch (this.context) {
      case 'backups': {
        this.actionsBunttos = backupButtons;
        this.isVisible = true;
        break;
      }
      case 'catalog': {
        this.actionsBunttos = catalogButtons;
        this.isVisible = true;
        break;
      }

      case 'fascicle': {
        this.actionsBunttos = fascicleButtons;
        this.isVisible = true;
        break;
      }

      case 'user': {
        this.actionsBunttos = userButtons;
        this.isVisible = true;
        break;
      }

      case 'pageTemplate': {
        this.actionsBunttos = pageTemplateButtons;
        this.isVisible = true;
        break;
      }

      default:
        this.isVisible = false;
        break;
    }
  }
}
