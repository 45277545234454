/*
 * File: page.service.ts                                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 15:43:07                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PageCell } from '../interfaces/page-cell.interface';
import { Page } from '../interfaces/page.interface';
import { PageTemplate } from '../interfaces/page-template.interface';
import { ApiService } from '../../api/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalDialogComponent } from '../../tms-tools/components/modal-dialog/modal-dialog.component';
import { PageDeleteComponent } from '../../../modules/catalog-cloud/components/page-delete/page-delete.component';
import { Fascicle } from '../interfaces/fascicle.interface';
import { environment } from 'src/environment';



@Injectable({
  providedIn: 'root',
})
export class PageService {
  private urlBase = environment.api_server;

  idCatalog: string = undefined;
  idFasiclce: string = undefined;
  pages: Page[] = [];
  pagesSubject = new Subject<Page[]>();
  reloadGrid$ = new Subject<boolean>();
  returnItemToToolbar$ = new Subject<string>();
  fascicle:Fascicle
  goToIndex: number = 0;

  private pageTemplates: PageTemplate[] = [];

  constructor(private api: ApiService, private modalDialog: MatDialog) {}


  savePages() {
    if (this.idCatalog && this.idFasiclce) {
      let body = {
        pages: this.pages,
        totalPages: this.pages.length,
      };
      console.log(body)
      return this.api.patch(
        this.urlBase + '/catalog/' + this.idCatalog + '/fascicles/' + this.idFasiclce,
        body
      );
    } else {
      return new Observable<any>((observer) => {
        observer.next(false);
        observer.complete();
      });
    }
  }

  async reorderPages(idCatalog: string, idFascicle: string) {
    this.api
      .get(this.urlBase + '/catalogs/' + this.idCatalog + '/update-pages')
      .subscribe((data) => {
        this.reloadPages(idCatalog, idFascicle);
      });
  }

  async reloadPages(idCatalog: string, idFascicle) {
    return this.api
      .get(this.urlBase + '/catalog/' + idCatalog + '/fascicles/' + idFascicle)
      .subscribe((data) => {
        this.pages = data.pages;
        this.pagesSubject.next(this.pages);
      });
  }

  getPageTemplates() {
    return this.pageTemplates;
  }

  setPageTemplates(pageTemplates: PageTemplate[]) {
    this.pageTemplates = pageTemplates;
  }

  updatePage(page: Page) {
    return this.api.patch(
      this.urlBase +  '/catalog/' +
        this.idCatalog +
        '/fascicles/' +
        this.idFasiclce +
        '/pages/' +
        page.id,
      page
    );
  }

  setPages(pages: Page[]) {
    this.pages = pages;
    this.pagesSubject.next(this.pages);
  }

  // Add Pages Last, First, before of and after of

  getNewPage(userId: string): Page {
    return {
      pageNumber: this.pages.length + 1,
      pageTemplate: this.pageTemplates[0],
      cells: this.addCells(this.pageTemplates[0], userId),
      id: undefined,
      createdBy: undefined
    };
  }

  addPage(userId: string) {
    let page: Page = this.getNewPage(userId);
    if (this.pages.length > 0)
    {
      page.pageNumber = this.pages[this.pages.length - 1].pageNumber + 1;

    }
    else{
      page.pageNumber = 0;
    }
    this.pages.push(page);

    this.savePages().subscribe((data) => {
      if (data) this.reorderPages(this.idCatalog, this.idFasiclce);
    });
  }

  addPageFirst(userId: string) {
    let page: Page = this.getNewPage(userId);
    page.pageNumber = this.pages[0].pageNumber;
    this.pages.unshift(page);
    this.savePages().subscribe((data) => {
      if (data) this.reorderPages(this.idCatalog, this.idFasiclce);
    });
  }

  addPageBefore(userId: string, pageNumber: number) {
    let page: Page = this.getNewPage(userId);
    let index = this.pages
      .map((p) => {
        return p.pageNumber;
      })
      .indexOf(pageNumber);

    page.pageNumber = this.pages[index].pageNumber;
    this.pages.splice(index, 0, page);
    this.savePages().subscribe((data) => {
      if (data) this.reorderPages(this.idCatalog, this.idFasiclce);
    });
  }

  addPageAfter(userId: string, pageNumber: number) {
    let page: Page = this.getNewPage(userId);
    let index = this.pages
      .map((p) => {
        return p.pageNumber;
      })
      .indexOf(pageNumber);
    if (index < this.pages.length) {
      page.pageNumber = this.pages[index].pageNumber + 1;
      this.pages.splice(index + 1, 0, page);
      this.savePages().subscribe((data) => {
        if (data) this.reorderPages(this.idCatalog, this.idFasiclce);
      });
    } else {
      this.addPage(userId);
    }
  }

  recoverItems(page: Page) {
    if (page.cells) {
      page.cells.forEach((c) => {
        if (c.product) {
          this.returnItemToToolbar$.next(c.product);
        }
      });
    }
  }

  removeProductsOnHiddenCells(page: Page) {
    page.cells.forEach((cell: PageCell) => {
      if (!cell.visible) {
        cell.product = null;
        cell.productModel = null;
      }
    });
    return page;
  }

  async removePage(id: string) {
    let removedPage = this.pages.find((p) => p.id === id);
    if (removedPage) {
      let index = this.pages.indexOf(removedPage);
      this.recoverItems(removedPage);
      this.pages.splice(index, 1);
      this.recalcPages();
      this.goToIndex = index > this.pages.length ? this.pages.length : index;
      this.savePages().subscribe((data) => {
        this.reorderPages(this.idCatalog, this.idFasiclce);
      });
    }
  }

  deleteModal(idPage: string) {
    return this.modalDialog
      .open(ModalDialogComponent, {
        width: 'auto',
        height: 'auto',
        panelClass: 'mat-elevation-z8',
        data: {
          title: 'Eliminar página',
          component: PageDeleteComponent,
          type: 'yesno',
        },
      })
      .afterClosed();
  }

  recalcPages() {
    for (let i = 0; i < this.pages.length; i++) {
      this.pages[i].pageNumber = i + 1;
    }
  }

  addCells(pageTemplate: PageTemplate, userId: string) {
    let totalCells = pageTemplate.columns * pageTemplate.rows;
    let cells: PageCell[] = [];
    let row = 0;
    let column = 0;

    for (let i = 0; i < totalCells; i++) {
      if (column >= pageTemplate.columns) {
        column = 0;
        row++;
      }
      cells.push({
        cellNumber: i,
        columnPosition: column + 1,
        columnWith: 1,
        rowPosition: row + 1,
        rowWidth: 1,
        productTable: 'products_' + this.idCatalog,
        createdBy: userId,
        id: '',
        visible: true,
      });

      column++;
    }
    return cells;
  }

  setCells(pageIndex: number, pageTemplate: PageTemplate, userId: string) {
    let cells = this.addCells(pageTemplate, userId);
    this.pages[pageIndex].cells = cells;

    this.savePages().subscribe((data) => {
      console.debug('Page Service.', 'Saved Pages');
    });
  }

  setGrid(pageIndex: number, pageTemplate: PageTemplate, userId: string) {
    this.pages[pageIndex].cells = this.addCells(pageTemplate, userId);
    this.pages[pageIndex].pageTemplate = pageTemplate;
    this.reloadGrid$.next(true);

    //this.pagesSubject.next(this.pages);
  }

  getPageTemplate(pageIndex: number): PageTemplate {
    let baseIndex = 0;
    if (typeof this.pages[pageIndex].pageTemplate === 'string') {
      baseIndex = this.pageTemplates
        .map((b) => b.id)
        .indexOf(this.pages[pageIndex].pageTemplate.toString());
      console.log('Encuentor el page template' + pageIndex);
      return this.pageTemplates[baseIndex];
    } else {
      console.log('Devuelvo el pageTemplate por defecto de: ' + pageIndex);
      return this.pageTemplates[0];
    }
  }

  rollBackPage(
    pageIndex: number,
    pageTemplate: PageTemplate,
    cells: PageCell[]
  ) {
    this.pages[pageIndex].cells = cells;
    this.pages[pageIndex].pageTemplate = pageTemplate;
    this.pagesSubject.next(this.pages);
  }
}
