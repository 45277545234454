<!--
File: cc-cell.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified:  Monday, 19th June 2023 4:13:11 pm
Modified By: Tomás (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->
<div
  class="innerCell"
  [ngClass]="[locked ? 'cell-locked' : 'cell-unlocked',item ? 'cell-fill' : 'cell-void']"
  cdkDropList
  id="cell-{{ cell.data.id }}"
  (cdkDropListDropped)="dropCard($event)"
  (cdkDropListEntered)="entering($event)"
  (cdkDropListExited)="exiting($event)"
>
<mat-progress-bar [mode]="'indeterminate'" *ngIf="loading"></mat-progress-bar>
  <app-cc-cell-item
    *ngIf="item && locked"
    [item]="item"
    (remove)="removeData()"
    (productModelChange)="changeProductModel($event)"
    (productVariantChange)="changeProductVariant($event)"
    (productExtraChange)="changeProductExtra($event)"
    [height]="height"
    [width]="width"
    [ngClass]="viewItem ? '':'hide-item'"
    [idPage]="idPage"
    [currentModel] ="cell.data.productModel"
    [currentVariant]="cell.data.productVariant"
    [currentExtra]="cell.data.productExtra"

  >
</app-cc-cell-item>


  <div class="cell-buttons" *ngIf="!locked">
    <app-cc-cell-button
      [grid]="grid"
      [cellId]="grid.cells.indexOf(cell)"
      [side]="'right'"
      class="side side-h side-right"
      (sizeChanged)="getCellSize()"
    ></app-cc-cell-button>
    <app-cc-cell-button
      [grid]="grid"
      [cellId]="grid.cells.indexOf(cell)"
      [side]="'left'"
      class="side side-h side-left"
      (sizeChanged)="getCellSize()"
    ></app-cc-cell-button>
    <app-cc-cell-button
      [grid]="grid"
      [cellId]="grid.cells.indexOf(cell)"
      [side]="'top'"
      class="side side-v side-top"
      (sizeChanged)="getCellSize()"
    ></app-cc-cell-button>
    <app-cc-cell-button
      [grid]="grid"
      [cellId]="grid.cells.indexOf(cell)"
      [side]="'bottom'"
      class="side side-v side-bottom"
      (sizeChanged)="getCellSize()"
    ></app-cc-cell-button>
  </div>


</div>
