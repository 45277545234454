/*
 * File: cc-autocomplete.component.ts                                          *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 2nd December 2022 14:06:46                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 1st March 2023 13:11:57                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { keyValue } from '../../../tms-tools/interfaces/key-value';

@Component({
  selector: 'app-cc-autocomplete',
  templateUrl: './cc-autocomplete.component.html',
  styleUrls: ['./cc-autocomplete.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class CcAutocompleteComponent implements OnInit, OnChanges {
  //Styles
  @Input() label: string = 'Input label';
  @Input() appeareance: string = 'outline';
  @Input() flexSize: number = 100;

  //Input configuration
  @Input() fg: FormGroup;
  @Input() name: string = '';
  @Input() mainField: string;

  //Data for autocomplete
  @Input() data: keyValue[];
  @Input() dataSelected: keyValue[];

  //Chips configuration
  @Input() chips:keyValue[];

  //Input result
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Output() removed: EventEmitter<any> = new EventEmitter();

  visibleData: keyValue[];
  filteredOptions: Observable<keyValue[]>;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataSelected']) {
      this.setVisibleData();
    }
  }

  ngOnInit(): void {
    this.setVisibleData();

  }

  setVisibleData() {
    this.visibleData = Object.assign([], this.data);
    if (!this.dataSelected) this.dataSelected = [];
    /**
     * Mostrado de los elementos no seleccionados en el autofill
     */
    this.dataSelected.forEach((item: keyValue) => {
      let itm = this.visibleData.filter((val) => val.id == item.id);
      if (itm.length > 0) {
        let i = this.visibleData.indexOf(itm[0]);
        this.visibleData.splice(i, 1);
      }
    });

    /**Filtrado en el autofill */
    this.filteredOptions = this.fg.get(this.name).valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.visibleData.slice()))
    );
    this.chips = this.dataSelected;
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.visibleData.filter((item) =>
      item.value.toLowerCase().includes(filterValue)
    );
  }

  displayFn(item: keyValue): string {
    return item.value;
  }

  returnSelected(value: keyValue) {
    this.chips.push(value)
    let selectedValue: keyValue = {
      id: this.mainField,
      value: value,
    };

    this.selected.emit(selectedValue);
    this.fg.get(this.name).setValue('');
  }

  returnRemove(value:keyValue){

    let removedValue: keyValue = {
      id: this.mainField,
      value: value,
    };

    this.chips.splice(this.chips.indexOf(value),1);

    this.removed.emit(removedValue)
    // let selectedValue: keyValue = {
    //   id: this.mainField,
    //   value: value,
    // };

    // this.selected.emit(selectedValue);
    // this.fg.get(this.name).setValue('');
  }
}
