/*
 * File: catalog-sync-differences.component.ts                                 *
 * Project: catalog-cloud                                                      *
 * File Created: Monday, 24th January 2022 13:51:36                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:42:52                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

// import { templateJitUrl } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Catalog } from '../../interfaces/catalog.interface';
import { CatalogService } from '../../services/catalog.service';

@Component({
  selector: 'app-catalog-sync-differences',
  templateUrl: './catalog-sync-differences.component.html',
  styleUrls: ['./catalog-sync-differences.component.scss'],
})
export class CatalogSyncDifferencesComponent implements OnInit {
  @Input() idCatalog: string;
  @Output() preSyncEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading: boolean = false;
  started: boolean = false;
  testConnectionIcon: string;
  connectionStatus: boolean;
  testConnectionMessage: string;
  catalog: Catalog;
  infoDifferences: any;

  connectionPreSyncSearching: string = 'Searching for differences...';
  connectionPreSyncResults: string = 'Showing results';

  result: any = false;

  syncError: boolean = false;

  constructor(
    private catalogService: CatalogService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.preSyncEmmiter.next(false);
  }

  testConnection() {
    this.started = true;
    this.syncError = false;
    this.preSyncEmmiter.next(false);
    this.loading = true;
    this.testConnectionIcon = 'settings';
    this.testConnectionMessage = this.translate.instant(
      'SALESLAYER.PREPAIR_SYNC'
    );
    this.catalogService.getCatalog(this.idCatalog).subscribe((cat) => {
      this.catalog = cat;
      var connector = this.catalog.salesLayerCredentials.key;
      var privateKey = this.catalog.salesLayerCredentials.pass;
      this.catalogService
        .testSalesLayerConnection(connector, privateKey)
        .subscribe((data) => {
          this.testConnectionIcon =
            data.status == 'ok'
              ? 'hourglass_top'
              : 'report_problem';
          this.connectionStatus = data.status == 'ok' ? true : false;
          // this.testConnectionMessage = data.data;
          if (this.connectionStatus) {
            this.testConnectionMessage = this.translate.instant(
              'SALESLAYER.SEARCHING_DIFFERENCES'
            );
            this.catalogService
              .checkDifferencesSalesLayer(this.idCatalog)
              .subscribe(
                (data) => {
                  this.infoDifferences = data;
                  this.loading = false;
                  this.preSyncEmmiter.next(true);
                  this.syncro();
                },
                (err) => {
                  this.syncError = true;
                  this.testConnectionIcon = 'report_problem';
                  this.testConnectionMessage = 'SALESLAYER.PRESYNC_ERROR';
                  this.started = false;
                }
              );
          } else {
            this.loading = false;
            this.preSyncEmmiter.next(false);
            this.started = false;
          }
        },
        (error) => {
          this.started = false;
          console.debug("Error:",error)
          this.testConnectionMessage = this.translate.instant(
            'SALESLAYER.ERROR_WAITING'
          );
          this.preSyncEmmiter.next(false);
          this.loading = false;
          this.started = false;
        }
        );
    });
  }

  syncro() {
    this.loading = true;
    this.result = null;
    this.syncError = false;
    this.testConnectionMessage = this.translate.instant(
      'SALESLAYER.START_SYNC'
    );
    this.testConnectionIcon = 'hourglass_top';

    this.catalogService
      .syncronizeSalesLayer(this.idCatalog)
      .subscribe((syncResume) => {
        this.loading = false;
        this.result = syncResume;

        if (syncResume == null) {
          this.syncError = true;
          this.testConnectionIcon = 'report_problem';
          this.testConnectionMessage = this.translate.instant(
            'SALESLAYER.PRESYNC_ERROR'
          );
          this.started = false;
        } else {
          this.testConnectionIcon = 'done';
          this.testConnectionMessage = this.translate.instant('SALESLAYER.SYNC_OK');
          // this.disableSync = true;
          // this.syncFinish.next();
        }
      });
  }
}
