/*
 * File: backups-list.component.ts                                             *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 16th June 2022 13:17:29                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 7th December 2022 12:24:38                        *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { io } from 'socket.io-client';
import { UserService } from 'src/app/modules/user/services/user.service';
import { PopInfoService } from 'src/app/modules/pop-info/services/pop-info-service.service';
import { Backup } from '../../interfaces/backup.interface';
import { BackupService } from '../../services/backup.service';

@Component({
  selector: 'app-backups-list',
  templateUrl: './backups-list.component.html',
  styleUrls: ['./backups-list.component.scss'],
})
export class BackupsListComponent implements OnInit, OnDestroy {
  backuplist = new Subscription();
  path: string;
  data: Backup[] = [];
  displayedColumns = [
    'name',
    'createdBy',
    'createdAt',
    'size',
    'auto',
    'action',
  ];
  constructor(
    private backupService: BackupService,
    private userService: UserService,
    private popup:PopInfoService
  ) {}

  ngOnInit(): void {
    this.suscriptions();
    this.backupService.getList();
  }
  ngOnDestroy(): void {
    this.backuplist.unsubscribe();
  }

  restore(id:string)
  {
    this.backupService.restore(id).subscribe((data)=>{
      this.popup.addMessage("info","Backup restaurado");
    })
  }

  download(id:string)
  {
    this.backupService.download(id).subscribe((data)=>{
      console.log(data);
      let url = window.URL.createObjectURL(data);
      var downloader = document.createElement('a');
      downloader.href = url;
      downloader.click();

    })
  }

  suscriptions() {
    this.backuplist = this.backupService.backuplist$.subscribe(
      (backups: Backup[]) => {
        this.data = backups;
        this.data.forEach((backup) => {
          backup.createdBy = this.userService.user.name.concat(' ', this.userService.user.surname);
          });

        this.data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      }
    );
  }
}
