<!--
File: product-form.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified:  Tuesday, 16th January 2024 1:46:05 pm
Modified By: Tomás (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->
<mat-drawer-container>
  <mat-drawer mode="side" opened>
    <mat-list>
      <mat-list-item
        class="version-list"
        *ngFor="let version of entity.versions; let i = index"
        ><div mat-line class="label version">Version: {{ i + 1 }}</div>
        <div mat-line class="label date">
          Creada: {{ version.createdAt | date }}
        </div></mat-list-item
      >
    </mat-list>
  </mat-drawer>

  <div
    *ngIf="!loading"
    fxFill
    fxLayout="column"
    fxLayoutAlign="center center"
    style="padding: 1em !important"
  >
    <!-- <img class="currentVersion-img mat-elevation-z3" src="{{ currentVersion.img }}" /> -->
    <mat-form-field appearance="outline" class="full-witdth">
      <mat-label>{{ "PRODUCT.SKU" | translate }} </mat-label>
      <input matInput type="text" [(ngModel)]="currentVersion.sku" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-witdth">
      <mat-label>{{ "NAME" | translate }} </mat-label>
      <input matInput type="text" [(ngModel)]="currentVersion.name" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-witdth">
      <mat-label>{{ "DESCRIPTION" | translate }} </mat-label>
      <input matInput type="text" [(ngModel)]="currentVersion.description" />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="full-witdth"
      *ngFor="let extra of currentVersion.extra; let i = index"
    >
      <mat-label>{{ extra.label }}</mat-label>
      <input matInput type="text" [(ngModel)]="currentVersion.extra[i].value" />
    </mat-form-field>
  </div>
</mat-drawer-container>
