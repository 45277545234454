/*
 * File: catalog-wizard-sync.component.ts                                      *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 26th January 2022 10:14:55                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 7th December 2022 10:04:49                        *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */




import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CatalogService } from '../../services/catalog.service';

@Component({
  selector: 'app-catalog-wizard-sync',
  templateUrl: './catalog-wizard-sync.component.html',
  styleUrls: ['./catalog-wizard-sync.component.scss'],
})
export class CatalogWizardSyncComponent implements OnInit {
  @Input() idCatalog: string;

  @ViewChild('stepper') private stepWizzard: MatStepper;
  @Output() closeForm: EventEmitter<void> = new EventEmitter<void>();

  checkDifferencesStep: FormGroup;
  checkDifferencesStepLabel: string = 'Preparar sincronización';
  syncStep: FormGroup;
  syncStepLabel: string = 'Sincronizar';
  catalogForm: FormGroup;

  preSyncIsValid: boolean = false;
  syncIsValid: boolean = false;

  constructor(
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.setForms();

  }

  setForms() {
    this.checkDifferencesStep = this.fb.group({
      isValid: ['', Validators.requiredTrue],
    });
    this.syncStep = this.fb.group({
      isValid: ['', Validators.requiredTrue],
    });
  }
  isPreSync(preSync: boolean) {
    this.preSyncIsValid = preSync;
    this.checkDifferencesStep.controls['isValid'].setValue(preSync);
  }

  goToSync() {
    this.stepWizzard.next();
  }

  setCloseForm() {
    this.closeForm.next();
  }

  setSyncFinish() {
    this.syncStep.controls['isValid'].setValue(true);
    this.syncIsValid = true;
  }
}
