<!--
 * File: edit.component.html                                                   *
 * Project: catalog-cloud                                                      *
 * File Created: Tuesday, 15th March 2022 11:52:45                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 16th March 2022 12:19:32                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->



<div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="show" >
  <form
  [formGroup]="form"
  *ngIf="form"
  autocomplete="off"
  class="base-fascicle-form"
  >
  <div formGroupName="mainFields" class="full-width"
  *ngIf="formDataIsLoaded"
  >
<

  
    <app-form-generator
    *ngFor="let field of orderedMainFields"
    [formGroup]="fbMain"
    [field]="field"

  ></app-form-generator>
</div>

  </form>

</div>
