/*
 * File: menus.module.ts                                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 2nd December 2022 14:06:46                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 27th February 2023 13:21:38                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */



import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { OptionsUserMenuComponent } from "../user/components/options-user-menu/options-user-menu.component";
import { UserModule } from "../user/user.module";
import { ActionsMenuComponent } from "./components/actions-menu/actions-menu.component";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { TopMenuComponent } from "./components/top-menu/top-menu.component";
import { ActionsMenuService } from "./services/actionsMenu.service";
import { SideMenuService } from "./services/sideMenu.service";
import { MatMenuModule } from "@angular/material/menu";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { RouterModule } from "@angular/router";
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        UserModule,
        TranslateModule,
        MatIconModule,
        MatDividerModule,
        MatListModule,
        RouterModule,
        MatToolbarModule,
        FlexLayoutModule,
        MatButtonModule
    ],
    declarations: [ActionsMenuComponent, SideMenuComponent, TopMenuComponent],
    exports: [
        ActionsMenuComponent,
        SideMenuComponent,
        TopMenuComponent,
        TranslateModule,
    ],
    providers: [SideMenuService, ActionsMenuService],
})
export class MenusModule {}
