/*
 * File: product-grid-item.component.ts                                        *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observer, Subscription } from 'rxjs';
import { Product } from '../../interfaces/product.interface';
import { ProductService } from '../../services/product.service';
@Component({
  selector: 'app-product-grid-item',
  templateUrl: './product-grid-item.component.html',
  styleUrls: ['./product-grid-item.component.scss']
})
export class ProductGridItemComponent implements OnInit,OnDestroy {

  @Input() item:Product;

  selected:boolean = false;
  showDetails:boolean = false;

  imSelected:Subscription = new Subscription();;
  elevation:string = '';
  constructor(private productService:ProductService) { }
  ngOnDestroy(): void {
    this.imSelected.unsubscribe();
  }

  ngOnInit(): void {
    this.imSelected = this.productService.viewDetails.subscribe((data:Product) => {
      if (data.id != this.item.id) this.selected = false;
      else this.selected = true;
    })
  }

  setDetails(e:any)
  {
    let details = document.getElementById('product-details-' +this.item.id);

    if (!this.showDetails)
    {
      details.style.left = e.x - 200 + 'px';
      details.style.top = e.y -30 + 'px';
      details.style.display = 'block';
      details.classList.remove('item-details-hide')

    }
    else{
      details.classList.add('item-details-hide')
    }
  }

  test(e:any)
  {
    console.log("Dropeado aqui")
  }


  select(item:any)
  {
    this.productService.selectedProduct(item);
  }

  addElevation(){
    this.elevation = 'mat-elevation-z5';
  }

  removeElevation(){
    this.elevation = '';

  }


}
