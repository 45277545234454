/*
 * File: fascicle-form.schema.ts                                               *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 7th December 2022 11:09:17                        *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { FormSchema } from "../../user/interfaces/form-schema.interface";


export const pageTemplateFormSchema:FormSchema = {
  'mainFields': [
    {
      key: 'name',
      type: 'text',
      value: '',
      label: 'Nombre de la plantilla',
      required: true,
      order: 1,
      data: null,
    },

    {
      key: 'id',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 4,
      data: null,
    },
    {
      key: 'updatedBy',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 5,
      data: null,
    },
    {
      key: 'updatedAt',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 6,
      data: null,
    },
    {
      key: 'createdBy',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 7,
      data: null,
    },
    {
      key: 'createdAt',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 8,
      data: null,
    },
    {
      key: 'columns',
      type: 'text',
      value: '',
      label: 'Columnas',
      required: true,
      order: 9,
      data: null,
    },
    {
      key: 'rows',
      type: 'text',
      required: true,
      value: '',
      label: 'Filas',
      order: 10,
      data: null,
    }
  ],
};
