/*
 * File: fascicle-form.component.ts                                            *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormCreator } from 'src/app/modules/form-generator/helpers/form-creator';
import { keyValue } from 'src/app/modules/tms-tools/interfaces/key-value';
import { ProductCategoriesService } from '../../services/product-categories.service';
import { ProductTagsService } from '../../services/product-tags.service';
import { ProductService } from '../../services/product.service';
import { FascicleService } from '../../services/fascicle.service';
import { Fascicle } from '../../interfaces/fascicle.interface';
import { fascicleFormSchema } from '../../schemas/fascicle-form.schema';

@Component({
  selector: 'app-fasicle-form',
  templateUrl: './fascicle-form.component.html',
  styleUrls: ['./fascicle-form.component.scss'],
})

/**
 * cambiar formato búsqueda de categorias
 */
export class FascicleFormComponent implements OnInit {
  @Input() id: string = '';
  @Input() idCatalog: string;

  loadedForm = false;

  isFullyLoaded: Promise<boolean>;

  form!: FormGroup;
  fgExtra: FormGroup;
  fgCategories: FormGroup;
  fgTags: FormGroup;
  fgMain: FormGroup;
  fgOptional: FormGroup;
  fasicle: Fascicle;
  formIsValid: boolean = false;

  categoryAutocomplete: string = 'categoryAutocomplete';
  tagAutocomplete: string = 'tagAutocomplete';

  categories: keyValue[] = [];
  tags: keyValue[] = [];
  selectedCategories: keyValue[] = [];
  childSelectedCategories: keyValue[] = [];

  selectedTags: keyValue[] = [];
  childSelectedTags: keyValue[] = [];

  dataAutoComplete: any[] = [];

  orderedMainFields = fascicleFormSchema.mainFields.sort(
    (a, b) => a.order - b.order
  );

  categoriesField = fascicleFormSchema.categoryFields;
  tagsField = fascicleFormSchema.tagFields;

  searchingProducts: boolean = false;
  totalProducts: number = 0;

  constructor(
    private fascicleService: FascicleService,
    private categoriesService: ProductCategoriesService,
    private tagsService: ProductTagsService,
    private productService: ProductService,
    private fb: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.id != '') await this.loadFascicle();
    else await this.newFascicle();
    this.isFullyLoaded = Promise.resolve(true);
    this.countFilteredProducts();
  }

  async newFascicle() {
    this.fasicle = this.fascicleService.initialize();
    this.setForm();
    this.fgExtra = this.form.get('extraFields') as FormGroup;
    this.fgMain = this.form.get('mainFields') as FormGroup;
    this.fgOptional = this.form.get('optionalFields') as FormGroup;
    await this.loadCategories();
    this.patchBaseValues();
  }

  patchBaseValues() {
    this.fgMain.patchValue(this.fasicle);
  }

  loadFascicle() {
    let url = this;
    this.fascicleService.get(this.idCatalog, this.id).subscribe((fas) => {
      this.fasicle = this.fascicleService.initialize();
      this.fasicle = fas;

      this.setForm();
      this.patchBaseValues();

      this.loadCategories();
    });
  }

  async loadCategories() {
    this.categoriesService
      .getByCatalog(this.idCatalog)
      .subscribe(async (categories) => {
        this.setCategoriesToKeyValue(categories);
        if (this.fasicle.categories) {
          this.fasicle.categories.forEach((category) => {
            let index = this.categories.findIndex((x) => x.id === category);
            if (index >= 0) this.addCategory(this.categories[index]);
          });
        }
        this.dataAutoComplete['categories'] = this.categories;
        this.dataAutoComplete['selectedCategories'] =
          this.childSelectedCategories;
        await this.loadTags();
      });
  }

  private setCategoriesToKeyValue(categories: any[]) {
    categories.forEach((cat: any) => {
      this.categories.push({
        id: cat.id,
        value: cat.composedName ? cat.composedName : cat.name,
      });
    });
  }

  private setTagsToKeyValue(tagData: string[]) {
    tagData.forEach((tag: string) => {
      this.tags.push({
        id: tag,
        value: tag,
      });
    });
  }

  async loadTags() {
    this.tagsService.getByCatalog(this.idCatalog).subscribe((tagsData) => {
      this.setTagsToKeyValue(tagsData);
      if (this.fasicle.tags) {
        this.fasicle.tags.forEach((tag) => {
          let index = this.tags.findIndex((x) => x.id === tag);
          if (index >= 0) this.addTag(this.tags[index]);
        });
      }

      this.dataAutoComplete['tags'] = this.tags;
      this.dataAutoComplete['selectedTags'] = this.childSelectedTags;
      this.loadedForm = true;
    });
  }

  addItem(data: keyValue) {
    switch (data.id) {
      case 'categoriesAuto':
        this.addCategory(data.value);
        break;
      case 'tagsAuto':
        this.addTag(data.value);
        break;
    }
  }

  removeItem(data: keyValue) {
    console.log(data);
    switch (data.id) {
      case 'categoriesAuto':
        this.removeCategory(data.value);
        break;
      case 'tagsAuto':
        this.removeTag(data.value);
        break;
    }
  }

  addCategory(a: keyValue) {
    this.selectedCategories.push(a);
    this.childSelectedCategories = Object.assign([], this.selectedCategories);
    this.refreshCategories();
  }

  removeCategory(id: string) {
    let index = this.selectedCategories.findIndex((i) => i.id == id);
    this.selectedCategories.splice(index, 1);
    this.childSelectedCategories = Object.assign([], this.selectedCategories);
    this.refreshCategories();
  }

  addTag(a: keyValue) {
    this.selectedTags.push(a);
    this.childSelectedTags = Object.assign([], this.selectedTags);
    this.refreshTags();
  }

  removeTag(id: string) {
    let index = this.selectedTags.findIndex((i) => i.id == id);
    this.selectedTags.splice(index, 1);
    this.childSelectedTags = Object.assign([], this.selectedTags);
    this.refreshTags();
  }

  hiddenFields() {
    let hiden: FormGroup = this.form.get('hiddenFields') as FormGroup;
    return Object.keys(hiden.controls);
  }

  setForm() {
    const fc = new FormCreator(this.fb);
    //TODO: DEBUG THIS
    fc.schemaToForm(fascicleFormSchema);
    this.form = fc.getControls();
    this.fgExtra = this.form.get('extraFields') as FormGroup;
    this.fgMain = this.form.get('mainFields') as FormGroup;
    // this.fgAutoFill = this.form.get('autoFillFields') as FormGroup;
    this.fgCategories = this.form.get('categoryFields') as FormGroup;
    this.fgTags = this.form.get('tagFields') as FormGroup;

    this.fgOptional = this.form.get('optionalFields') as FormGroup;
    this.fgMain.patchValue({
      idCatalog: this.idCatalog,
    });
  }

  refreshCategories() {
    let iris: string[] = [];
    this.selectedCategories.forEach((cat) => iris.push(cat.id));
    this.fgMain.patchValue({
      categories: iris,
    });
    this.countFilteredProducts();
  }

  refreshTags() {
    let iris: string[] = [];
    this.selectedTags.forEach((cat) => iris.push(cat.id));
    this.fgMain.patchValue({
      tags: iris,
    });
    this.countFilteredProducts();
  }

  setMainName(e: any) {
    this.fgMain.patchValue({
      name: e,
    });
  }

  countFilteredProducts() {
    this.searchingProducts = true;
    this.productService
      .countProducts(
        this.idCatalog,
        this.selectedCategories.map((c) => c.id),
        this.selectedTags.map((t) => t.id)
      )
      .subscribe((data) => {
        this.totalProducts = data;
        this.searchingProducts = false;
      });
  }
}
