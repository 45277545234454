/*
 * File: catalog-cloud.module.ts                                               *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 2nd December 2022 14:33:51                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 6th March 2023 09:59:20                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { FormGeneratorModule } from "../form-generator/form-generator.module";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { MatCardModule } from "@angular/material/card";
import { MatOptionModule } from "@angular/material/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";

import { MatTooltipModule } from "@angular/material/tooltip";
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { BreadCrumbModule } from "../breadcrumbs/breadcrumbs.module";
import { CatalogActionMenuComponent } from "./components/catalog-action-menu/catalog-action-menu.component";
import { CatalogDeleteComponent } from "./components/catalog-delete/catalog-delete.component";
import { CatalogFormComponent } from "./components/catalog-form/catalog-form.component";
import { CatalogHomeComponent } from "./components/catalog-home/catalog-home.component";
import { CatalogListComponent } from "./components/catalog-list/catalog-list.component";
import { CatalogSyncComponent } from "./components/catalog-sync/catalog-sync.component";
import { CatalogSyncDifferencesComponent } from "./components/catalog-sync-differences/catalog-sync-differences.component";
import { CatalogUploadComponent } from "./components/catalog-upload/catalog-upload.component";
import { CatalogWizardComponent } from "./components/catalog-wizard/catalog-wizard.component";
import { CatalogWizardSyncComponent } from "./components/catalog-wizard-sync/catalog-wizard-sync.component";
import { CcCellComponent } from "./components/cc-cell/cc-cell.component";
import { CcCellButtonComponent } from "./components/cc-cell-button/cc-cell-button.component";
import { CcCellItemComponent } from "./components/cc-cell-item/cc-cell-item.component";
import { CcCellItemExtraFieldsFormComponent } from "./components/cc-cell-item-extra-fields-form/cc-cell-item-extra-fields-form.component";
import { CcCellItemModelSelectorComponent } from "./components/cc-cell-item-model-selector/cc-cell-item-model-selector.component";
import { CcCellItemVariantSelectorComponent } from "./components/cc-cell-item-variant-selector/cc-cell-item-variant-selector.component";
import { CcGridComponent } from "./components/cc-grid/cc-grid.component";
import { CcGridSelectorComponent } from "./components/cc-grid-selector/cc-grid-selector.component";
import { FascicleAskUnblockComponent } from "./components/fascicle-ask-unblock/fascicle-ask-unblock.component";
import { FascicleDeleteComponent } from "./components/fascicle-delete/fascicle-delete.component";
import { FascicleEditComponent } from "./components/fascicle-edit/fascicle-edit.component";
import { FascicleFormComponent } from "./components/fascicle-form/fascicle-form.component";
import { FascicleListComponent } from "./components/fascicle-list/fascicle-list.component";
import { FascicleListAloneComponent } from "./components/fascicle-list-alone/fascicle-list-alone.component";
import { PageDeleteComponent } from "./components/page-delete/page-delete.component";
import { PageTemplateFormComponent } from "./components/page-template-form/page-template-form.component";
import { PageTemplateListComponent } from "./components/page-template-list/page-template-list.component";
import { ProductDetailsComponent } from "./components/product-details/product-details.component";
import { ProductFormComponent } from "./components/product-form/product-form.component";
import { ProductGridComponent } from "./components/product-grid/product-grid.component";
import { ProductGridItemComponent } from "./components/product-grid-item/product-grid-item.component";
import { ProductListComponent } from "./components/product-list/product-list.component";
import { ProductListItemComponent } from "./components/product-list-item/product-list-item.component";
import { ProductToolbarComponent } from "./components/product-toolbar/product-toolbar.component";
import { ProductToolbarProductDetailsComponent } from "./components/product-toolbar-product-details/product-toolbar-product-details.component";
import { ProductToolbarSearchComponent } from "./components/product-toolbar-search/product-toolbar-search.component";
import { ProductToolbarSearchInfoComponent } from "./components/product-toolbar-search-info/product-toolbar-search-info.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { TmsToolsModule } from "../tms-tools/tms-tools.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatStepperModule } from "@angular/material/stepper";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MenusModule } from "../menus/menus.module";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatChipsModule } from "@angular/material/chips";
import {MatRadioModule} from '@angular/material/radio';
import { TranslateModule } from "@ngx-translate/core";
import { CatalogListActionsComponent } from './components/catalog-list-actions/catalog-list-actions.component';



@NgModule({
    imports: [
        BreadCrumbModule,
        CommonModule,
        FormGeneratorModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        BrowserModule,
        RouterModule,
        MatCardModule,
        MatOptionModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatTooltipModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        FlexLayoutModule,
        MatDatepickerModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatPaginatorModule,
        MatStepperModule,
        MatSidenavModule,
        MatChipsModule,
        MatRadioModule,
        TranslateModule,
        DragDropModule,
        TmsToolsModule,
        MenusModule
    ],
    declarations: [
       CatalogActionMenuComponent,
       CatalogDeleteComponent,
       CatalogFormComponent,
       CatalogHomeComponent,
       CatalogListComponent,
       CatalogSyncComponent,
       CatalogSyncDifferencesComponent,
       CatalogUploadComponent,
       CatalogWizardComponent,
       CatalogWizardSyncComponent,
       CcCellComponent,
       CcCellButtonComponent,
       CcCellItemComponent,
       CcCellItemExtraFieldsFormComponent,
       CcCellItemModelSelectorComponent,
       CcCellItemVariantSelectorComponent,
       CcGridComponent,
       CcGridSelectorComponent,
       FascicleAskUnblockComponent,
       FascicleDeleteComponent,
       FascicleEditComponent,
       FascicleFormComponent,
       FascicleListComponent,
       FascicleListAloneComponent,
       PageDeleteComponent,
       PageTemplateFormComponent,
       PageTemplateListComponent,
       ProductDetailsComponent,
       ProductFormComponent,
       ProductGridComponent,
       ProductGridItemComponent,
       ProductListComponent,
       ProductListItemComponent,
       ProductToolbarComponent,
       ProductToolbarProductDetailsComponent,
       ProductToolbarSearchComponent,
       ProductToolbarSearchInfoComponent,
       CatalogListActionsComponent
    ],
    exports: [

    ],
    providers: [],
})
export class CatalogCloudModule {}


