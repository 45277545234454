/*
 * File: catalog-form.component.ts                                             *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 17:31:59                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';

import { FormCreator } from 'src/app/modules/form-generator/helpers/form-creator';
import { CatalogTemplate } from '../../interfaces/catalog-template.interface';
import { CatalogService } from '../../services/catalog.service';
import { CatalogExtra } from '../../interfaces/catalog-extra.interface';
import { CatalogForm } from '../../interfaces/catalog-form.interface';
import { Catalog } from '../../interfaces/catalog.interface';
import { catalogFormSchema } from '../../schemas/catalog-form.schema';
import { AuthModel } from 'src/app/modules/user/models/auth.model';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { IUser } from 'src/app/modules/user/interfaces/user.interface';

@Component({
  selector: 'app-catalog-form',
  templateUrl: './catalog-form.component.html',
  styleUrls: ['./catalog-form.component.scss'],
})
export class CatalogFormComponent implements OnInit, AfterViewInit {
  @Input() id: string = '';
  @Output() frmToParent: EventEmitter<FormGroup> =
    new EventEmitter<FormGroup>();
  form!: FormGroup;
  fgMain: FormGroup;
  fgExtra: FormGroup;

  catalog: CatalogForm;
  extraControls: FormControl[] = [];
  templates: CatalogTemplate[];
  schema = catalogFormSchema;
  orderedMainFields = catalogFormSchema.mainFields.sort(
    (a, b) => a.order - b.order
  );
  loadedForm: boolean = false;
  formIsValid: boolean = false;
  chekingConnection:boolean = false;

  templateImg: string = '';
  defaultSelected: string = '';
  cat: Observable<any>;

  extraFields: CatalogExtra[] = [];

  testConnectionShow: boolean = false;
  testConnectionIcon: string = '';
  testConnectionMessage: string = '';
  user:IUser;
  constructor(
    private fb: FormBuilder,
    private catalogService: CatalogService,
    private api:ApiService,
    private cookie:CookieService
  ) {
    let auth = new AuthModel(api,cookie);
    this.user = auth.getCurrentUser();
  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.setForm();
    this.catalog = this.catalogService.intializeForm();
    if (this.id != '') {
      this.loadCatalogInForm();
    }
    else this.loadNewCatalogInForm();
    this.checkSalesLayerConnectionButton();
  }

  checkSalesLayerConnectionButton() {
    this.form.valueChanges.subscribe((data) => {
      
      this.testConnectionShow =
        data.mainFields.connector.length > 0 &&
        data.mainFields.privateKey.length > 0;
    });
  }

  testConnection() {
    var connector = this.form.get('mainFields').get('connector').value;
    var privateKey = this.form.get('mainFields').get('privateKey').value;
    this.chekingConnection = true;
    this.catalogService
      .testSalesLayerConnection(connector, privateKey)
      .subscribe((data) => {
        console.log(data);
        this.chekingConnection = false;
        this.testConnectionIcon =
          data.status == 'ok' ? 'task_alt' : 'report_problem';
        if (data.status != 'ok')
        {
          this.form.get('mainFields').get('connector').setErrors({'crentials':false});
          this.form.get('mainFields').get('privateKey').setErrors({'crentials':false});
        }
        else{
          this.form.get('mainFields').get('connector').setErrors(null);
          this.form.get('mainFields').get('privateKey').setErrors(null);
        }
        if (data.data.message) this.testConnectionMessage = data.data.message;
        else if (typeof(data.data) == 'string') this.testConnectionMessage = data.data;
        else this.testConnectionMessage = JSON.stringify(data.data);
      });
  }

  loadNewCatalogInForm() {
    this.catalog.mainFields.createdBy = this.user.id;
    this.catalog.mainFields.updateBy = this.user.id;
    this.form.get('mainFields').patchValue(this.catalog.mainFields);
    this.form.get('extraFields').patchValue(this.catalog.extraFields);
    this.loadedForm = true;
  }

  loadCatalogInForm() {
    this.loadedForm = false;
    this.catalogService.get(this.id).subscribe((catalog: Catalog) => {
      this.form.get('mainFields').patchValue(catalog);
      this.form
        .get('mainFields')
        .patchValue({ connector: catalog.salesLayerCredentials.key });
      this.form
        .get('mainFields')
        .patchValue({ privateKey: catalog.salesLayerCredentials.pass });
      catalog.extra?.forEach((field: CatalogExtra) => {
        this.addExtraField(field);
      });

      this.loadedForm = true;
    });
  }

  addExtraField(field: CatalogExtra) {
    let extraForm = this.form.get('extraFields') as FormGroup;
    extraForm.addControl(field.name, new FormControl(field.value));
    this.extraFields.push({
      label: field.label,
      name: field.name,
      value: field.value,
    });
  }

  setForm() {
    const fc = new FormCreator(this.fb);
    //TODO:Revisar si está correcto
    fc.schemaToForm(catalogFormSchema);
    this.form = fc.getControls();
    this.frmToParent.emit(this.form);
    this.form.valueChanges.subscribe(() => {
      this.frmToParent.emit(this.form);
    });

    this.fgMain = this.form.get('mainFields') as FormGroup;
    this.fgExtra = this.form.get('extraFields') as FormGroup;
  }

  setImg(value: string) {
    this.templates.forEach((template) => {
      if (template['@id'] == value) {
        //this.defaultSelected = value;

        this.templateImg = template.posterImage;
      }
    });
  }

  changeImg(e: MatSelectChange) {
    this.setImg(e.value);
  }

  addField(e: HTMLInputElement) {
    if (e.value.length > 0) {
      this.extraFields.push({
        label: e.value,
        name: e.value.replace(/ /g, '_'),
        value: '',
      });
      let extraFields = this.form.get('extraFields') as FormGroup;
      extraFields.addControl(e.value.replace(/ /g, '_'), new FormControl(''));
      e.value = '';
    }
  }
}
