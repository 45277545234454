/*
 * File: fascicle-ask-unblock.component.ts                                     *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 1st March 2023 13:12:37                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CatalogService } from '../../services/catalog.service';
import { FascicleService } from '../../services/fascicle.service';
import { UserService } from 'src/app/modules/user/services/user.service';
import { UserModel } from 'src/app/modules/user/models/user.model';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-fascicle-ask-unblock',
  templateUrl: './fascicle-ask-unblock.component.html',
  styleUrls: ['./fascicle-ask-unblock.component.scss'],
})
export class FascicleAskUnblockComponent implements OnInit {
  @Input() fascicleData: any;

  catalogId: string;
  catalogName: string;
  fascicleId: string;
  fascicleName: string;
  claimantId: string;
  blockedBy: string;
  claimantName: string;

  userModel: UserModel;

  constructor(
    private catalogService: CatalogService,
    private fascicleService: FascicleService,
    api: ApiService,
    cookie: CookieService,
    private userService: UserService
  ) {
    this.userModel = new UserModel(api, cookie);
  }

  ngOnInit(): void {
    console.log(this.fascicleData);
    this.fascicleId = this.fascicleData.fascicleId;
    this.catalogId = this.fascicleData.catalogId;
    this.claimantId = this.fascicleData.claimantId;
    if (this.fascicleData) {
      this.catalogService.getCatalog(this.catalogId).subscribe(async (data) => {
        this.catalogName = data.name;
        this.fascicleName = data.fascicles.filter(
          (f: any) => f.id === this.fascicleId
        )[0].name;
        let user = await this.userModel.getUserById(this.claimantId);
        this.claimantName = user.name + ' ' + user.surname;
      });
    }
  }
}
