/*
 * File: menu.component.ts                                                     *
 * Project: core                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 16th March 2022 13:16:55                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnInit } from "@angular/core";
import { IUser } from "src/app/modules/user/interfaces/user.interface";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { UserModel } from "../../models/user.model";
import { ApiService } from "src/app/modules/api/services/api.service";
import { CookieService } from "ngx-cookie-service";
import { ModalUserForms } from "../../helpers/modal-user-forms.helper";
import { PopInfoService } from "src/app/modules/pop-info/services/pop-info-service.service";
import { AuthModel } from "../../models/auth.model";

@Component({
    selector: "options-user-menu",
    templateUrl: "./options-user-menu.component.html",
    styleUrls: ["./options-user-menu.component.scss"],
})
export class OptionsUserMenuComponent implements OnInit {
    @Input() user: IUser;
    userModel: UserModel;
    userModalForms: ModalUserForms;
    auth:AuthModel;
    role:string;
    constructor(
        dialog: MatDialog,
        traslate: TranslateService,
        api: ApiService,
        cookie: CookieService,
        private router: Router,
        popup:PopInfoService,
        private userService:UserService
    ) {
        this.userModalForms = new ModalUserForms(dialog, traslate,popup,api, cookie,userService);
        this.userModel = new UserModel(api, cookie);
        this.auth = new AuthModel(api,cookie);
        this.role = this.userService.getRole();
    }

    ngOnInit(): void {}

    logout() {
        this.userModel.logout();
        this.userService.user$.next(undefined);
        this.router.navigate(["/login"]);
    }

    editMe() {
        this.userModalForms.editForm(this.auth.getCurrentUser());
    }
}
