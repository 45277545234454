<!--
File: list.component.html
Project: catalog-cloud
File Created: Thursday, 3rd March 2022 15:43:37
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:58:51
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<div class="user-list-container">
  <button
  mat-raised-button
  color="primary"
  routerLink="/tasks/new"
  (click)="createUser()"
>
  Add new user
</button>
  <mat-table *ngIf="!loading" [dataSource]="usersDataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef
        >{{ "NAME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let user">
        {{ user.name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="surname">
      <mat-header-cell *matHeaderCellDef
        >{{ "USER.SURNAME" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let user">
        {{ user.surname }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef
        >{{ "USER.USER" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let user">
        {{ user.username }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef
        >{{ "USER.ROLE" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let user">
        <mat-icon [color]="user.role == 1 ? 'accent' : 'primary'">
          {{ user.role == 1 ? "person" : "manage_accounts" }}</mat-icon
        >
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="blocked">
      <mat-header-cell *matHeaderCellDef
        >{{ "BLOCKED" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let user">
        <mat-icon>{{ user.blocked ? "lock" : "lock_open" }}</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef
        >{{ "ACTIONS" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let user">
        <button mat-icon-button color="primary" (click)="reset(user.username)"><mat-icon>lock_reset</mat-icon></button>
        <button mat-icon-button color="primary" (click)="welcome(user.username)"><mat-icon>mail</mat-icon></button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
