/*
 * File: cell.service.ts                                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:25:31                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import { ApiService } from '../../api/services/api.service';
import { PageCell } from '../interfaces/page-cell.interface';
import { Subject } from 'rxjs';
import { ProductExtra } from '../interfaces/product-extra';
import { CcCellItemExtraFieldsFormComponent } from '../components/cc-cell-item-extra-fields-form/cc-cell-item-extra-fields-form.component';
import { ModalDialogComponent } from '../../tms-tools/components/modal-dialog/modal-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root',
})
export class CellService {
  protected urlBase = environment.api_server;

  endpoint: string =
    this.urlBase + '/catalog/idCatalog/fascicle/idFascicle/page/idPage/cells';

  cleanCell: Subject<string> = new Subject<string>();
  moveCell: Subject<string> = new Subject<string>();

  constructor(private api: ApiService, private dialog: MatDialog) {}

  private getBaseEndPoint(
    idCatalog: string,
    idFascicle: string,
    idPage: string
  ) {
    const mapObj = {
      idCatalog: idCatalog,
      idFascicle: idFascicle,
      idPage: idPage,
    };
    let str = this.endpoint;
    str = str.replace(
      /\b(?:idCatalog|idFascicle|idPage)\b/gi,
      (matched) => mapObj[matched]
    );
    return str;
  }

  moveProduct(
    idCatalog: string,
    idFascicle: string,
    fromIdPage: string,
    fromCell: string,
    toIdPage: string,
    toCell: string
  ) {
    const url =
      this.getBaseEndPoint(idCatalog, idFascicle, fromIdPage) + '/' + fromCell;
    return this.api.post(url, { toIdPage: toIdPage, toCell: toCell });
  }

  update(
    idCatalog: string,
    idFascicle: string,
    idPage: string,
    idCell: string,
    cell: PageCell
  ) {
    const url =
      this.getBaseEndPoint(idCatalog, idFascicle, idPage) + '/' + idCell;
    return this.api.patch(url, cell);
  }

  openExtraFieldsForm(fields: ProductExtra[]) {
    return this.dialog
      .open(ModalDialogComponent, {
        width: '40vw',
        height: 'auto',
        closeOnNavigation: false,
        disableClose: true,
        data: {
          title: 'Editar campos extra',
          component: CcCellItemExtraFieldsFormComponent,
          entity: fields,
          type: 'entity',
        },
      })
      .afterClosed();
  }
}
