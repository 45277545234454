/*
 * File: list.component.ts                                                     *
 * Project: core                                                      *
 * File Created: Thursday, 3rd March 2022 15:43:37                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 10th March 2022 23:54:31                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { Observer, Subscription } from "rxjs";
import { ApiService } from "src/app/modules/api/services/api.service";
import { PopInfoService } from "src/app/modules/pop-info/services/pop-info-service.service";
import { UserList } from "src/app/modules/user/interfaces/user-list.interface";
import { ModalUserForms } from "../../helpers/modal-user-forms.helper";
import { UserModel } from "../../models/user.model";
import { UserService } from "../../services/user.service";

@Component({
    selector: "app-list",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit, OnDestroy {
    displayedColumns = [
        "name",
        "surname",
        "user",
        "role",
        "blocked",
        "actions",
    ];

    usersDataSource: MatTableDataSource<UserList> =
        new MatTableDataSource<UserList>();
    loading = true;

    changes: Subscription;
    userModalForms: ModalUserForms;
    userModel:UserModel
    constructor(
        private userService: UserService,
        private api: ApiService,
        dialog: MatDialog,
        translate: TranslateService,
        popup: PopInfoService,
        cookie: CookieService
    ) {
        this.userModalForms = new ModalUserForms(
            dialog,
            translate,
            popup,
            api,
            cookie,
            userService
        );
        this.userModel = new UserModel(api,cookie);
    }
    ngOnDestroy(): void {
        this.stopListen();
    }

    ngOnInit(): void {
        this.listenChanges();
        this.loadData();
    }

    loadData() {
        this.loading = true;
        this.userModel.getUsers().subscribe((result) => {
            let users: UserList[] = result;
            this.usersDataSource.data = users;
            this.loading = false;
        });
    }

    listenChanges() {
        this.changes = this.userService.userChanged.subscribe((data) => {
            this.loadData();
        });
    }

    stopListen() {
        this.changes.unsubscribe();
    }

    reset(mail: string) {
        this.api.get("/users/recover/" + mail).subscribe((data) => {
            console.log(data);
        });
        console.log(mail);
    }

    welcome(mail: string) {
        this.api.get("/users/welcome/" + mail).subscribe((data) => {
            console.log(data);
        });
        console.log(mail);
    }

    createUser() {
        this.userModalForms.registerForm();
    }
}
