/*
 * File: product-details.component.ts                                          *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 27th January 2022 23:46:22                          *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../interfaces/product.interface';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  @Input() item:Product;
  @Output() hideMe:EventEmitter<void> = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {



  }

  hide(){
    let details = document.getElementById('product-details-' +this.item.id);
    details.classList.add('item-details-hide');
     this.hideMe.emit();
  }



}
