<!--
File: top-menu.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Wednesday, 9th March 2022 00:02:04
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<mat-toolbar class="top-toolbar" color="primary">
    <mat-toolbar-row style="padding-left: 0em !important" class="top-toolbar-row">
        <!-- <button class="toogle-sidenav" (click)="toogleSideNav()">
            <mat-icon
                [ngClass]="
                    sidenavIsExpanded ? 'collapse-sidemenu' : 'expand-sidemenu'
                "
                >menu</mat-icon
            >
        </button> -->
        <h1 [routerLink]="'/'"><img src="./assets/img/nousmedis_logo.svg" class="logo-img"></h1>

        <div fxFlex fxLayoutAlign="flex-end center" fxLatout="row">
          <cc-actions-menu fxFlex="10" [context]="context"></cc-actions-menu>


          <options-user-menu *ngIf="this.user" [user]="user" fxFlex="5" ></options-user-menu>
        </div>

    </mat-toolbar-row>
</mat-toolbar>
