/*
 * File: app.component.ts                                                      *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {
  NavigationEnd,
  Router,
  Event as NavigationEvent,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './modules/api/services/api.service';
import { FascicleSocketService } from './modules/catalog-cloud/services/fascicle-socket.service';
import { IUser } from './modules/user/interfaces/user.interface';
import { MatIconSvg } from './styles/mat-icon-svg';
import { UserService } from './modules/user/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { langs } from 'src/assets/i18n/available-lang';
import { Subscription } from 'rxjs';
import { AuthModel } from './modules/user/models/auth.model';
import { UserModel } from './modules/user/models/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px' })),
      transition('false <=> true', animate(500)),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Catalog Cloud';
  user: IUser;
  router: Router;
  noLoggedRoute: Promise<string> = null;

  @ViewChild('sidenav') public sidenav: MatSidenav;

  currentRoute: string;
  user$: Subscription;
  auth: AuthModel;
  userModel: UserModel;

  constructor (
    private api: ApiService,
    private cookie: CookieService,
    private _router: Router,
    private matIconsvg: MatIconSvg,
    private userService: UserService,
    private fascicleSocketService: FascicleSocketService,
    public translate: TranslateService
  ) {
    this.userModel = new UserModel(api, cookie);
    this.auth = new AuthModel(api, cookie);
    this.user = this.auth.getCurrentUser();
    this.router = _router;
    if (this.user) {
      fascicleSocketService.startSocket(this.user.id);
      // Register translation languages
      translate.addLangs(
        langs.map((l) => {
          return l.value;
        })
      );
      // console.log(this.user.lang)
      // Set default language
      if (this.user.lang && this.user.lang.length > 0)
        translate.setDefaultLang(this.user.lang);
      else translate.setDefaultLang('es_ES');
    } else translate.setDefaultLang('es_ES');
  }
  ngOnDestroy(): void {
    this.user$.unsubscribe();
  }
  ngOnInit(): void {
    this.observerUser();
  }

  translateLanguageTo(lang: string) {
    this.translate.use(lang);
  }

  passUrl(navigation: string) {
    return Promise.resolve(navigation);
  }

  observerUser() {
    this.user$ = this.userService.user$.subscribe((user) => {
      this.user = user;
    });
  }
}
