export class ApiParam {
    key: string;
    value: any;
    constructor(key: string, value: any) {
        this.key = key;
        this.value = value;
    }
}

export class ApiParams {
    params: ApiParam[];

    constructor() {
        this.params = [];
    }
}
