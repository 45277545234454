import { CommonModule } from "@angular/common";
import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CcAutocompleteComponent } from "./components/cc-autocomplete/cc-autocomplete.component";
import { FormGeneratorComponent } from "./components/form-generator/form-generator.component";
import { FormCreator } from "./helpers/form-creator";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import {MatChipsModule} from '@angular/material/chips';
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatOptionModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatIconModule,
        
    ],
    declarations: [CcAutocompleteComponent, FormGeneratorComponent],
    exports: [CcAutocompleteComponent, FormGeneratorComponent, TranslateModule],
    providers: [FormCreator]
})
export class FormGeneratorModule {}
