/*
 * File: login.component.ts                                                    *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 9th December 2022 11:24:13                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CookieService } from 'ngx-cookie-service';

import { PopInfoService } from 'src/app/modules/pop-info/services/pop-info-service.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { IUser } from 'src/app/modules/user/interfaces/user.interface';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { UserModel } from '../../models/user.model';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { AuthModel } from '../../models/auth.model';
import { TranslateService } from '@ngx-translate/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'cc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  frm: FormGroup;

  user: IUser | undefined;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passFormControl = new FormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();
  @ViewChild('chkRemember') remember: MatCheckbox;
  // remember = new MatCheckbox(chkRem));

  msgError: string = '';
  badCredentials: boolean = false;

  hide: boolean = true;
  disable: boolean = false;

  userModel: UserModel;
  auth: AuthModel;

  constructor(
    private cookie: CookieService,
    private fb: FormBuilder,
    private userService: UserService,
    private route: Router,
    private popup: PopInfoService,
    private translate: TranslateService,
    api: ApiService
  ) {
    this.auth = new AuthModel(api, cookie);

    this.user = this.auth.getCurrentUser();
    this.userModel = new UserModel(api, cookie);
  }

  ngOnInit(): void {
    this.setForm();
    this.route;
    let msg = this.cookie.get('login-msg');

    if (msg.length > 0) {
      //this.popup.addMessage("info", msg);
      this.cookie.delete('login-msg');
    }
  }

  setForm() {
    this.frm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  setLogedUSer(data: any) {
    this.user = this.auth.decodeToken(data['token']) as IUser;
    this.auth.setJwsTokenCookie(data['token'], data['refresh_token']);
    this.userService.user$.next(this.user);
    this.userService.user = this.user;
    let that = this;
    let test = this.auth.getCurrentUser();
    that.route.navigateByUrl('/');
  }

  tryLogin() {
    this.disable = true;
    var data = {
      username: this.frm.get('email').value,
      password: this.frm.get('password').value,
    };

    this.auth.tryLogin(data.username, data.password).subscribe({
      next: (data) => this.setLogedUSer(data),
      error: (e) =>
        this.popup.addMessage(
          'Error',
          this.translate.instant('USER.BAD_LOGIN')
        ),
    });
  }

  logout() {
    this.auth.logOut();
    this.userService.user$.next(undefined);
    this.route.navigate(['/login']);
  }
}
