import { Component, Input, OnInit } from '@angular/core';
import { CatalogService } from '../../services/catalog.service';
import { FascicleService } from '../../services/fascicle.service';
import * as JSZip from 'jszip';


@Component({
  selector: 'app-catalog-list-actions',
  templateUrl: './catalog-list-actions.component.html',
  styleUrls: ['./catalog-list-actions.component.scss']
})
export class CatalogListActionsComponent implements OnInit {

  @Input() idCatalog = '';
  actionsBunttos: any[];
  constructor (private catalogService: CatalogService,
    private fascicleService: FascicleService) { }

  ngOnInit(): void {
  this.setButtons();
  }


  setButtons() {
    const catalogButtons = [
      {
        name: 'editCatalog',
        disabled: false,
        active: true,
        clickButton: () => this.editCatalog(),
        tooltip: 'MENU.EDIT_CATALOG',
        tooltipPostion: 'right',
        icon: 'settings',
        type: 'button',
      },
      {
        type: 'separator',
        info: 'End of Fascicle Menu',
      },
      {
        name: 'exportCatalog',
        disabled: false,
        active: true,
        clickButton: () => this.exportCatalog(),
        tooltip: 'MENU.EXPORT_CATALOG',
        tooltipPostion: 'down',
        icon: 'ios_share',
        type: 'button',
      },
      {
        name: 'exportCsv',
        disabled: false,
        active: true,
        clickButton: () => this.exportCsv(),
        tooltip: 'MENU.EXPORT_CSV',
        tooltipPostion: 'right',
        icon: 'subject',
        type: 'button',
      },
      {
        type: 'separator',
        info: 'End of Catalog Export Menu',
      },
      {
        name: 'cloneCatalog',
        disabled: false,
        active: true,
        clickButton: () => this.cloneCatalog(),
        tooltip: 'MENU.CLONE_CATALOG',
        tooltipPostion: 'right',
        icon: 'content_copy',
        type: 'button',
      },
      {
        type: 'separator',
        info: 'End of Catalog Menu',
      },
      {
        name: 'delCatalog',
        disabled: false,
        active: true,
        clickButton: () => this.removeCatalogs(),
        tooltip: 'MENU.DEL_CATALOG',
        tooltipPostion: 'right',
        icon: 'delete_outline',
        type: 'button',
      },
    ];
    this.actionsBunttos = catalogButtons;
  }

  cloneCatalog() {
    this.catalogService.clone(this.idCatalog);
  }

  newFascicle() {

    this.fascicleService.newFascicle(this.idCatalog);
  }

  exportCatalog() {
    this.catalogService.openUploadForm(this.idCatalog);
  }

  exportCsv() {
    var zip: JSZip = typeof (<any>JSZip).default === "function" ? new (<any>JSZip).default() : undefined;
    this.catalogService
      .exportCsvHeaders(this.idCatalog)
      .subscribe(
        async (data) => {
          let head = data.head;
          let lines = data.lines;
          zip.file(
            'head_' + this.idCatalog + '.csv',
            head
          );
          zip.file(
            'lines_' + this.idCatalog + '.csv',
            lines
          );
          zip.generateAsync({ type: 'base64' }).then((zipped) => {
            var downloader = document.createElement('a');
            downloader.setAttribute(
              'href',
              'data:text/plain;base64;charset=utf-8,' + zipped
            );
            downloader.setAttribute(
              'download',
              'csv_schema_catalog_' +
              this.idCatalog +
              '.zip'
            );
            downloader.click();
          });

          // this.actionsService.reset();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  removeCatalogs() {
    this.catalogService.deleteCatalog(this.idCatalog);

  }


  editCatalog() {
    
      this.catalogService.openForm(this.idCatalog);
  }

  syncCatalog() {
      this.catalogService.openSyncForm(this.idCatalog);
  }

}
