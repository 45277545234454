/*
 * File: user-repeated.validator.ts                                                    *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 4th March 2022 10:13:12                               *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 15th March 2022 11:37:56                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

//Validador cogido por pinzas por la llamada al backend

import { Injectable } from "@angular/core";
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "../../api/services/api.service";
import { UserModel } from "../../user/models/user.model";
import { UserService } from "../../user/services/user.service";

@Injectable({ providedIn: "root" })
export class UserRepeatedValidator {
  private userModel:UserModel
    constructor(
        private userService: UserService,
        private translate: TranslateService,
        api:ApiService,
        cookie:CookieService
    ) {
      this.userModel = new UserModel(api,cookie);
    }
    isValid(field: string): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors | null => {
            let ctrl = formGroup.get(field);
            let pattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$");

            let match = pattern.test(ctrl.value);
            if (match) {
                ctrl.setErrors(null);
                return this.isRepeated(ctrl);
            } else {
                ctrl.setErrors({
                    message: this.translate.instant("USER.INVALID_MAIL"),
                });
            }
            return null;
        };
    }

    private isRepeated(ctrl: AbstractControl) {
        this.userModel.exists(ctrl.value).subscribe((result) => {
            if (result) {
                ctrl.setErrors({
                    message: this.translate.instant("USER.EXISTS"),
                });
                return { message: this.translate.instant("USER.EXISTS") };
            } else {
                ctrl.setErrors(null);
                return null;
            }
        });
        return null;
    }
}
