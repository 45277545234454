/*
 * File: product-tags.service.ts                                               *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Injectable } from '@angular/core';
import { ApiService } from '../../api/services/api.service';
import { environment } from 'src/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductTagsService {
  private urlBase = environment.api_server;

  private endPoint =  this.urlBase + '/catalog/:id/tags';

  private pagination = false;
  private pagintaionParam = 'pagination=' + this.pagination;
  constructor(private api:ApiService) { }

  getUrl(idCatalog:string ){
    var regex = /:id/g;
    return this.endPoint.replace(regex, function(match, p1) {
        return idCatalog || ':' + p1;
    });
  }

  getByCatalog(id:string){
    return this.api.get(this.getUrl(id));
  }

}
