/*
 * File: cc-cell-button.component.ts                                           *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 7th December 2022 10:05:25                        *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { CcGrid } from '../../models/cc-grid.model';
import { CcGridCell } from '../../models/cc-grid-cell.model';
import { CcGridCellButton } from '../../models/cc-grid-cell-button.model';
import { PageCell } from '../../interfaces/page-cell.interface';

@Component({
  selector: 'app-cc-cell-button',
  templateUrl: './cc-cell-button.component.html',
  styleUrls: ['./cc-cell-button.component.scss'],
})
export class CcCellButtonComponent implements OnInit {
  @Input() side: string;
  @Input() cellId: number;
  @Input() grid: CcGrid;

  @Output() sizeChanged: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('button') button: MatButton;

  cells: PageCell[];
  cell: CcGridCell;
  cellButton: CcGridCellButton;

  visible: boolean = false;

  arrowMore: string = 'arrow-up';
  arrowLess: string = 'arrow-down';

  constructor() {}

  ngOnInit(): void {
    this.cell = this.grid.cells[this.cellId];
    this.cellButton = new CcGridCellButton(this.cellId, this.grid, this.side);
    this.setButtonRotation();
  }

  /**
   * Triquiñuelas para usar el icono de flecha vertical en todas las posiciones posibles
   */
  setButtonRotation() {
    switch (this.side) {
      case 'right':
        this.arrowMore = 'arrow-right';
        this.arrowLess = 'arrow-left';
        break;
      case 'left':
        this.arrowMore = 'arrow-left';
        this.arrowLess = 'arrow-right';
        break;
      case 'top':
        this.arrowMore = 'arrow-up';
        this.arrowLess = 'arrow-down';
        break;
      case 'bottom':
        this.arrowMore = 'arrow-down';
        this.arrowLess = 'arrow-up';
        break;
    }
  }

  isVisible() {
    return this.cellButton.showIncrease();
  }

  showDecrease() {
    return this.cellButton.showDrecrease();
  }

  expand() {
    switch (this.side) {
      case 'right':
        this.cell.expandRight();

        break;
      case 'left':
        this.cell.expandLeft();
        break;
      case 'top':
        this.cell.expandTop();
        break;
      case 'bottom':
        this.cell.expandBottom();
        break;
    }
    this.sizeChanged.emit();
  }

  reduce() {
    switch (this.side) {
      case 'bottom':
        this.cell.shrinkBottom();
        break;
      case 'top':
        this.cell.shrinkTop();
        break;
      case 'left':
        this.cell.shrinkLeft();
        break;
      case 'right':
        this.cell.shrinkRight();
        break;
    }
    this.sizeChanged.emit();
  }
}
