/*
 * File: page-template-form.component.ts                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 1st June 2022 13:50:02                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd March 2023 14:40:19                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */


import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageTemplate } from '../../interfaces/page-template.interface';
import { pageTemplateFormSchema } from '../../schemas/page-template-form.schema';
import { PageTemplateService } from '../../services/page-template.service';
import { FormCreator } from 'src/app/modules/form-generator/helpers/form-creator';


@Component({
  selector: 'app-page-template-form',
  templateUrl: './page-template-form.component.html',
  styleUrls: ['./page-template-form.component.scss']
})
export class PageTemplateFormComponent implements OnInit {

  @Input() idTemplate:string;
  @Input() idUSer:string;
  template:PageTemplate;
  form!: FormGroup;
  fgMain: FormGroup;

  loadedForm:boolean = false;
  constructor(private fb: FormBuilder,private pageTemplateService:PageTemplateService) { }
  sortedFields = pageTemplateFormSchema.mainFields.sort(
    (a, b) => a.order - b.order
  );
  ngOnInit(): void {
    if (this.idTemplate) this.loadPageTemplate();
    else this.createNewPageTemplate();
  }

  loadPageTemplate(){
    this.pageTemplateService.getTemplate(this.idTemplate).subscribe((data)=>{
      this.template = data;
      this.template.updatedBy = this.idUSer;
      this.setForm();
    })
  }

  createNewPageTemplate(){
    this.template = {
      columns:1,
      createdBy:this.idUSer,
      id:'',
      name:'',
      rows:1,
      updatedBy:this.idUSer
    }
    this.setForm();
  }

  setForm() {
    const fc = new FormCreator(this.fb);
    //TODO:DEBUG THIS
    fc.schemaToForm(pageTemplateFormSchema)
    this.form = fc.getControls();
    this.fgMain = this.form.get('mainFields') as FormGroup;
    this.fgMain.patchValue(this.template);
    this.loadedForm = true;
  }

}
