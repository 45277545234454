/*
 * File: app.module.ts                                                         *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 5th January 2023 15:43:56                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';

import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CatalogCloudModule } from './modules/catalog-cloud/catalog-cloud.module';
import { MenusModule } from './modules/menus/menus.module';
import { BackupModule } from './modules/backups/backup.module';
import { BreadCrumbModule } from './modules/breadcrumbs/breadcrumbs.module';
import { PopInfoModule } from './modules/pop-info/pop-info.module';
import { TmsToolsModule } from './modules/tms-tools/tms-tools.module';
import { FascicleService } from './modules/catalog-cloud/services/fascicle.service';
import { CatalogService } from './modules/catalog-cloud/services/catalog.service';
import { UserService } from './modules/user/services/user.service';
import { UserModule } from './modules/user/user.module';
import { AuthInterceptor } from './modules/user/interceptors/auth-interceptor';
// Factory function required during AOT compilation
export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CatalogCloudModule,
    HttpClientModule,
    MatSidenavModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MenusModule,
    BackupModule,
    BreadCrumbModule,
    CatalogCloudModule,
    PopInfoModule,
    TmsToolsModule,
    UserModule,
  ],
  exports: [TranslateModule],
  providers: [
    FascicleService,
    CatalogService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
