/*
 * File: paginator.ts                                                          *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 7th December 2022 11:26:08                        *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Subject } from 'rxjs';

type PaginationIndex = {
  key:number,
  value:string;
}

export class Paginator {
  pageNumbers: number[];
  pages: number;
  index: number;
  _index: Subject<number[]> = new Subject();

  // slice: number; //Relacionador con el total de items a mostrar

  totalPages: number;
  itemsPerPage: number;

  pagination: any[] = [];
  loading: boolean = true;
  combo:PaginationIndex[]=[];



  constructor(pages: number[], index: number, itemsPerPage: number) {
    this.pageNumbers = pages;
    this.pages = pages.length;
    this.index = index;
    this.itemsPerPage = itemsPerPage;
    this.totalPages = this.pages;
    this.setPagination();
  }

  reload(pages: number[]){
    this.pageNumbers = pages;
    this.pages = pages.length;
    this.totalPages = this.pages;
    this.setPagination();
  }

  reloadCombo(){
    this.getComboPagination();
  }


  setPagination() {
    this.loading = true;
    let mainArray: any[] = [];
    let i = 0;
    while (i < this.pages) {
      let currentSub: any[] = [];
      let iPerPage =
        i == 0 && this.pageNumbers[0] % 2 != 0 ? 1 : this.itemsPerPage;
      for (let z = 0; z < iPerPage; z++) {
        if (i < this.pages) currentSub.push(i);
        i++;
      }
      mainArray.push(currentSub);
    }
    this.pagination = mainArray;
    //console.log("Paginación",this.pagination);
    this.loading = false;
  }

  currentPage(): number[] {
    return this.pagination[this.index];
  }

  next() {
    if (this.index < this.pages) this.index++;
    this._index.next(this.pagination[this.index]);
  }

  previous() {
    if (this.index > 0) this.index--;
    this._index.next(this.pagination[this.index]);
  }

  goToPage(indexOfPage:number)
  {
    let index = 0;
    for (let i =0; i< this.pagination.length;i++)
    {
      // console.log("Paginación actual","Indice de página: " + indexOfPage);
      // console.log("Paginación actual",this.pagination[i]);
        this.pagination[i].forEach((pageIndex) => {
          if (indexOfPage == pageIndex) {
            index = i;
            i = this.pagination.length;
          }
        });
    }
    this.goToIndex(index);
  }

  goToIndex(index:any)
  {
    this.index = index;
    this._index.next(this.pagination[this.index]);
  }

  goToFirst(){
    this.index = 0;
    this._index.next(this.pagination[this.index]);
  }

  goToLast(){
    this.index = this.pagination.length -1;
    this._index.next(this.pagination[this.index]);
  }

  nextIsDisabled() {
    return this.index >= this.pagination.length - 1;
  }

  previousIsDisabled() {
    return this.index <= 0;
  }

  updatePages(total: number) {
    this.pages = total;
    this.setPagination();
    if (this.pagination.length == 0) this.index = 0;
    else if (this.index >= this.pagination.length)
      this.index = this.pagination.length - 1;
    this.getComboPagination();
    if (this.index < 0) this.index = 0;
    this._index.next(this.pagination[this.index]);
  }

  getCurrentPages() {
    let currentPages = '';
    if (typeof this.pagination[this.index] === 'undefined') {
      this.index = this.pagination.length - 1;
    }
    if (this.pagination.length > 0) {
      if (this.pagination[this.index].length == 1) {
        currentPages = (Number(this.pagination[this.index]) + 1).toString();
      } else {
        for (let i = 0; i < this.pagination[this.index].length; i++) {
          if (i > 0) currentPages += ' - ';
          currentPages += (
            Number(this.pagination[this.index][i]) + 1
          ).toString();
        }
      }
    }

    return currentPages;
  }

  getComboPagination() {
    this.combo = [];
    if (this.pagination.length > 0) {
      for (let i = 0; i < this.pagination.length; i++) {
        let page:PaginationIndex = {
          key : i,
          value :''
        };

        if (this.pagination[i].length == 1) {
          page.value = this.pageNumbers[this.pagination[i]].toString();
        } else {
          for (let z = 0; z < this.pagination[i].length; z++) {
            if (z > 0)  page.value += ' - ';
            page.value  += (
              this.pageNumbers[Number(this.pagination[i][z])]
            ).toString();
          }
        }
        this.combo.push(page)
      }
    }
    return this.combo;
  }

  getPagesOfIndex(pageIndex: any) {
    console.log(pageIndex);
  }

  getTotalPages() {
    return this.pages;
  }
}
