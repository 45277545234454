/*
 * File: cc-cell-item-variant-selector.component.ts                            *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 25th May 2022 13:51:32                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 7th December 2022 10:06:48                        *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductVariant } from '../../interfaces/product-variant';

@Component({
  selector: 'app-cc-cell-item-variant-selector',
  templateUrl: './cc-cell-item-variant-selector.component.html',
  styleUrls: ['./cc-cell-item-variant-selector.component.scss']
})
export class CcCellItemVariantSelectorComponent implements OnInit {

  @Input() variantSelected: ProductVariant;
  @Output() variantSelectedChange = new EventEmitter();

  constructor () { }
  @Input() variants: ProductVariant[];
  ngOnInit(): void {
    //console.log(this.variants)
  }

  changeVariant(model: ProductVariant) {
    this.variantSelected = model;
    this.variantSelectedChange.emit(model);
  }

}
