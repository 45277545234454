/*
 * File: cc-cell.component.ts                                                  *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 6th March 2023 10:07:23                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { fromEvent, Observable, Subscription } from 'rxjs';
import { CcGrid } from '../../models/cc-grid.model';
import { PageCell } from '../../interfaces/page-cell.interface';
import { CcGridCell } from '../../models/cc-grid-cell.model';
import { CellService } from '../../services/cell.service';
import { ProductService } from '../../services/product.service';
import { Product } from '../../interfaces/product.interface';
import { ProductVariant } from '../../interfaces/product-variant';
import { ProductExtra } from '../../interfaces/product-extra';

@Component({
  selector: 'app-cc-cell',
  templateUrl: './cc-cell.component.html',
  styleUrls: ['./cc-cell.component.scss'],
})
export class CcCellComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() cellId: number;
  @Input() grid: CcGrid;
  @Input() locked: boolean;
  @Input() idCatalog: string;
  @Input() idFascicle: string;
  @Input() idPage: string;

  idRemoveImgBtn = '';
  cell: CcGridCell;
  subs = new Subscription();
  dragulaSelector: string;
  width = 0;
  height = 0;
  item: Product;
  itemId: string;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  cleanCell:Subscription;
  moveCell:Subscription;
  loading: boolean = true;

  viewItem: boolean = true;

  filled: boolean;

  constructor(
    private cellService: CellService,
    private productService: ProductService
  ) {}

  /**
   * Lectura incorrecta de las medidas.
   * Volver a probar en el grid para la correcta medida de la celda
   */

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getCellSize();
    }, 1);
  }

  ngOnInit(): void {
    this.cell = this.grid.cells[this.cellId];

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.idRemoveImgBtn = 'remove-img-' + this.idPage + '-' + this.cellId;
    if (this.cell.data.product) this.itemId = this.cell.data.product;
    this.loadProduct();

    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      this.getCellSize();
    });
    this.listenForSubscriptions();
  }

  listenForSubscriptions() {
   this.cleanCell = this.cellService.cleanCell.subscribe((cellId) => {
      if (cellId === 'cell-' + this.cell.data.id) {
        this.removeData();
      }
    });
    this.moveCell = this.cellService.moveCell.subscribe((cellId) => {
      if (cellId === 'cell-' + this.cell.data.id) {
        this.moveData();
      }
    })

  }

  loadProduct() {
    this.loading = true;
    if (this.itemId) {
      this.productService
        .getProduct(this.idCatalog, this.itemId)
        .subscribe((product) => {
          this.item = product;
          if (this.item) this.filled = true;
          this.loading = false;
        });
    } else {
      this.loading = false;
      this.filled = false;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.resizeSubscription$.unsubscribe();
    this.cleanCell.unsubscribe();
  }

  dropCard(e: any) {
    // this.item = e.item;
    let idPrevious: string = e.previousContainer.id;
    //TODO: 19/5/2022
    //move the product if come from another cell (cell-) the incoming cell
    if (idPrevious.includes('cell-')) {
      let idPreviousPage = e.item.data[4];  //Recoge la id de la página donde estaba la celda
      if (this.item){
        this.productService.removeProduct$.next(this.item.id);
      }
      this.cellService
        .moveProduct(
          this.idCatalog,
          this.idFascicle,
          idPreviousPage,
          idPrevious.replace('cell-', ''),
          this.idPage,
          this.cell.data.id
        )
        .subscribe((data) => {
          this.cellService.moveCell.next(idPrevious);
          this.item = e.item.data[0];
          this.cell.data.product = e.item.data[0].id; //6/3/2023 Added id at cell -> data -> product
          this.cell.data.productModel = e.item.data[1];
          this.cell.data.productVariant = e.item.data[2];
          this.cell.data.productExtra = e.item.data[3];
          this.viewItem = true;
          this.filled = true;
        });

    } else {
      // set the data to cellD

      this.item = e.item.data;
      //Remove product if previous has selected
      if (this.cell.data.product)
        this.productService.removeProduct$.next(this.item.id);
      if (e.item.data) this.cell.data.product = e.item.data.id;
      if (e.item.data.modelos)
        this.cell.data.productModel = e.item.data.modelos.split(';')[0];
      this.viewItem = true;

      this.updateCell(this.cell.data);
    }
  }

  updateCell(data: PageCell) {
    console.log("Data:",data)
    this.cellService
      .update(this.idCatalog, this.idFascicle, this.idPage, data.id, data)
      .subscribe((product) => {
        console.log("Llego al subscribe");
        if (data.product) {
          this.productService.addProduct$.next(data.product);
        }
      });
  }

  changeProductModel(productModel: string) {
    this.cell.data.productModel = productModel;
    this.updateCell(this.cell.data);
  }

  changeProductVariant(productVariant: ProductVariant) {
    this.cell.data.productVariant = productVariant;
    this.updateCell(this.cell.data);
  }

  changeProductExtra(productExtra:ProductExtra[]){
    this.cell.data.productExtra = productExtra;
    this.updateCell(this.cell.data);
  }

  getCellSize() {
    this.height =
      (document.getElementById('page-' + this.idPage).offsetHeight /
        this.grid.rows) *
      this.cell.data.rowWidth;

    this.width =
      (document.getElementById('page-' + this.idPage).offsetWidth /
        this.grid.columns) *
      this.cell.data.columnWith;
  }

  removeData() {
    this.productService.removeProduct$.next(this.cell.data.product);
    this.item = null;
    this.cell.data.product = null;
    this.cell.data.productModel = null;
    this.cell.data.productVariant = null;
    this.cell.data.productExtra = [];
    this.updateCell(this.cell.data);
  }

  moveData(){
    this.item = null;
    this.cell.data.product = null;
    this.cell.data.productModel = null;
    this.cell.data.productVariant = null;
    this.cell.data.productExtra = [];
    this.updateCell(this.cell.data);
  }

  entering(e: any) {
    this.viewItem = false;
  }

  exiting(e: any) {
    this.viewItem = true;
  }
}
