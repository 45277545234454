/*
 * File: create.component.ts                                                   *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 3rd March 2022 15:43:42                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 28th February 2023 22:06:47                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
    AfterContentChecked,
    ChangeDetectionStrategy,
    Component,
    OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { FormCreator } from "src/app/modules/form-generator/helpers/form-creator";
import { FgValidators } from "src/app/modules/form-generator/validators/fg-validators";
import { UserRepeatedValidator } from "src/app/modules/form-generator/validators/user-repeated.validator";
import { UserService } from "../../services/user.service";
import { userRegisterSchema } from "../../schemas/user-register.schema";
import { ApiService } from "src/app/modules/api/services/api.service";
import { CookieService } from "ngx-cookie-service";
@Component({
    selector: "app-create",
    templateUrl: "./create.component.html",
    styleUrls: ["./create.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateComponent implements OnInit, AfterContentChecked {
    form!: FormGroup;
    fbMain!: FormGroup;
    fc: FormCreator;
    show: boolean = false;
    orderedMainFields = userRegisterSchema.mainFields.sort(
        (a, b) => a.order - b.order
    );

    userRepeatedValidator: UserRepeatedValidator;
    constructor(
        fb: FormBuilder,
        userService: UserService,
        translate: TranslateService,
        api: ApiService,
        cookie: CookieService
    ) {
        this.userRepeatedValidator = new FgValidators.UserRepeatedValidator(
            userService,
            translate,
            api,
            cookie
        );
        this.fc = new FormCreator(fb);
    }

    ngOnInit(): void {
        this.show = false;
        this.fc.schemaToForm(userRegisterSchema);
        this.form = this.fc.getControls();

        console.log(this.form.controls);
        this.fbMain = this.form.controls["mainFields"] as FormGroup;
        console.log(this.fbMain);

        this.fbMain.addValidators(
            FgValidators.SameAsValidator("password", "password2")
        );
        this.fbMain.addValidators(
            this.userRepeatedValidator.isValid("username")
        );
        // this.form.setErrors({start:true})
        this.show = true;
    }

    ngAfterContentChecked(): void {}
}
