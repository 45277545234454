<!--
 * File: cc-grid-selector.component.html                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 4th May 2022 15:43:50                              *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 6th March 2023 09:58:13                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->

<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="grid-selector-row"
>
  <button mat-icon-button (click)="toggle()">
    <mat-icon>{{ lockedIcon }}</mat-icon>
  </button>
  
  <mat-select
    [disabled]="lock"
    [value]="this.selectedGrid.id"
    (selectionChange)="changeFormat($event)"
    class="grid-selector"
  >
    <mat-option [value]="grid['id']" *ngFor="let grid of grids">
      {{ grid.name }}
    </mat-option>
  </mat-select>

  <button mat-icon-button (click)="removePage(pageId)" [disabled]="!lock">
    <mat-icon>delete_forever</mat-icon>
  </button>
</div>
