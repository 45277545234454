<!--
File: app.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified:  Tuesday, 16th January 2024 1:43:16 pm
Modified By: Tomás (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->
<router-outlet></router-outlet>



